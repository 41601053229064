import React, { Component } from "react";
import "./index.scss";
import { Button, Icon, message, Modal, Input, Select } from "antd";
import { addAnnouncement } from "../../../../helpers/ajax/announcementAjax";
import LibraryModal from "../../Library/LibraryModal";
import "./index.scss";
import { CDN_URL } from "../../../../config";
const { Option } = Select;

class AddAnnouncements extends Component {
  state = {
    announcement: {
      id: undefined,
      announcement_title: "",
      announcement_message: "",
      announcement_image: "",
      announcement_click: {
        type: "no_action",
        url: "",
        code: "",
        button_text: ""
      }
    },
    libraryModalVisible: false,
    libraryModalTab: "library",
    edit: false,
    errors: {}
  };
  componentWillReceiveProps(nextProps) {
    this.resetModal(nextProps.announcement);
  }

  toggleLibraryModal(tab = "library") {
    this.setState({
      libraryModalVisible: !this.state.libraryModalVisible,
      libraryModalTab: tab
    });
  }

  onImageChoose(media) {
    this.setField("announcement_image", media);
    this.toggleLibraryModal();
  }
  setField(field, value) {
    if (field === "announcement_image") {
      value = value.media_filename ? value.media_filename : "";
    }
    this.setState({
      announcement: {
        ...this.state.announcement,
        [field]: value
      }
    });
  }
  setAnnouncement(field, value) {
    this.setState({
      announcement: {
        ...this.state.announcement,
        announcement_click: {
          ...this.state.announcement.announcement_click,
          [field]: value
        }
      }
    });
  }

  resetModal(announcement = {}) {
    this.setState({
      announcement: {
        id: announcement._id ? announcement._id["$id"] : undefined,
        announcement_title: announcement.announcement_title
          ? announcement.announcement_title
          : "",
        announcement_message: announcement.announcement_message
          ? announcement.announcement_message
          : "",
        announcement_image: announcement.announcement_image
          ? announcement.announcement_image
          : "",
        announcement_click: {
          type: announcement.announcement_click
            ? announcement.announcement_click.type
            : "no_action",
          code: announcement.announcement_click
            ? announcement.announcement_click.code
            : "",
          url: announcement.announcement_click
            ? announcement.announcement_click.url
            : "",
          button_text: announcement.announcement_click
            ? announcement.announcement_click.button_text
            : ""
        }
      },
      libraryModalVisible: false
    });
  }

  saveAnnouncement() {
    const announcement = this.state.announcement;
    let errors = {};
    if (announcement.announcement_title.trim().length === 0) {
      errors["announcement_title"] = "This is a required field";
    }
    if (announcement.announcement_message.trim().length === 0) {
      errors["announcement_message"] = "This is a required field";
    }
    if (announcement.announcement_image.trim().length === 0) {
      errors["announcement_image"] = "This is a required field";
    }
    if (
      announcement.announcement_click.type === "redirect" ||
      announcement.announcement_click.type === "redirect_newtab"
    ) {
      if (announcement.announcement_click.url.trim().length === 0) {
        errors["announcement_click"] = "Please enter a valid url";
      }
    }
    if (
      announcement.announcement_click.type === "jscode" ||
      announcement.announcement_click.type === "yt_embed"
    ) {
      if (announcement.announcement_click.code.trim().length === 0) {
        errors["announcement_click"] = "Please enter a valid code";
      }
    }
    if (
      announcement.announcement_click.type === "jscode" ||
      announcement.announcement_click.type === "yt_embed" ||
      announcement.announcement_click.type === "redirect" ||
      announcement.announcement_click.type === "redirect_newtab"
    ) {
      if (announcement.announcement_click.button_text.trim().length === 0) {
        errors["announcement_click"] = "Please enter a CTA text!";
      }
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors
      });
    } else {
      this.setState(
        {
          errors: {}
        },
        () => {
          addAnnouncement(this.props.match.params.domid, announcement)
            .then(res => {
              if (res.data.success === 1) {
                message.success("Announcement Saved Successfully!");
                if (this.props.onAddAnnouncement) {
                  this.props.onAddAnnouncement(res.data.announcement);
                }
              } else {
                message.error(res.data.msg);
              }
            })
            .catch(e => {
              message.error(e.message);
            });
        }
      );
    }
  }

  render() {
    return (
      <>
        <Modal
          visible={this.props.visible}
          className="add-announcement-modal"
          onCancel={() => this.props.toggle()}
          afterClose={() => this.resetModal()}
          title="Add Announcement"
          footer={null}
        >
          <div className="add-announcements-form">
            <div className="ant-form-item-control">
              <label>Title</label>
              <Input
                placeholder="Enter title"
                value={this.state.announcement.announcement_title}
                onChange={e =>
                  this.setField("announcement_title", e.target.value)
                }
              />
              {this.state.errors.announcement_title && (
                <div className="ant-form-explain">
                  {this.state.errors.announcement_title}
                </div>
              )}
            </div>

            <div className="ant-form-item-control">
              <label>Announcement's Image</label>
              <div className="upload-actions">
                {this.state.announcement.announcement_image.length > 0 && (
                  <div className="upload-action">
                    <img
                      src={`${CDN_URL}/announcements/${this.state.announcement.announcement_image}`}
                      alt="reviewer"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("library")}
                >
                  <p>
                    <Icon type="folder-open" style={{ color: "#D9D9D9" }} />{" "}
                    <br />
                    Library
                  </p>
                </div>
                <div
                  className="upload-action"
                  onClick={() => this.toggleLibraryModal("upload")}
                >
                  <p>
                    <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                    Upload
                  </p>
                </div>
              </div>
              {this.state.errors.announcement_image && (
                <div className="ant-form-explain">
                  {this.state.errors.announcement_image}
                </div>
              )}
            </div>

            <div className="ant-form-item-control">
              <label>Message</label>
              <Input.TextArea
                placeholder="Enter Announcement Message"
                value={this.state.announcement.announcement_message}
                onChange={e =>
                  this.setField("announcement_message", e.target.value)
                }
              />
              {this.state.errors.announcement_message && (
                <div className="ant-form-explain">
                  {this.state.errors.announcement_message}
                </div>
              )}
            </div>

            <div className="ant-form-item-control">
              <label className="form-labels">
                What happens when visitor clicks the alert
              </label>
              <Select
                style={{ width: 172 }}
                value={this.state.announcement.announcement_click.type}
                onChange={val => this.setAnnouncement("type", val)}
              >
                <Option value="no_action" selected="">
                  No Action
                </Option>
                <Option value="redirect">Redirect in same tab</Option>
                <Option value="redirect_newtab">Redirect in new tab</Option>
                <Option value="jscode" data-label="JS Code">
                  Trigger JS Code
                </Option>
                <Option value="yt_embed" data-label="Youtube Embed Code">
                  Youtube Embed
                </Option>
              </Select>

              {(this.state.announcement.announcement_click.type ===
                "redirect" ||
                this.state.announcement.announcement_click.type ===
                  "redirect_newtab") && (
                <Input
                  placeholder="Enter Redirect URL"
                  type="url"
                  defaultValue={this.state.announcement.announcement_click.url}
                  onChange={e => {
                    this.setAnnouncement("url", e.target.value);
                  }}
                  style={{ maxWidth: 280, marginLeft: 20 }}
                />
              )}

              {(this.state.announcement.announcement_click.type === "jscode" ||
                this.state.announcement.announcement_click.type ===
                  "yt_embed") && (
                <Input.TextArea
                  placeholder=""
                  type="url"
                  defaultValue={this.state.announcement.announcement_click.code}
                  onChange={e => {
                    this.setAnnouncement("code", e.target.value);
                  }}
                  style={{
                    maxWidth: 280,
                    marginLeft: 20,
                    verticalAlign: "top"
                  }}
                />
              )}
              {this.state.announcement.announcement_click.type !==
                "no_action" && (
                <div className="ant-form-item-control">
                  <label>CTA Text</label>
                  <Input
                    placeholder="CTA Text"
                    value={
                      this.state.announcement.announcement_click.button_text
                    }
                    onChange={e =>
                      this.setAnnouncement("button_text", e.target.value)
                    }
                  />
                </div>
              )}
              {this.state.errors.announcement_click && (
                <div className="ant-form-explain">
                  {this.state.errors.announcement_click}
                </div>
              )}
            </div>
            <div
              className="ant-form-item-control"
              style={{ marginTop: "2.35rem" }}
            >
              <Button
                className="submit-btn"
                size="large"
                onClick={() => this.saveAnnouncement()}
              >
                Save Announcement
              </Button>
            </div>
          </div>
        </Modal>
        <LibraryModal
          {...this.props}
          tab={this.state.libraryModalTab}
          onCancel={() => this.toggleLibraryModal()}
          visible={this.state.libraryModalVisible}
          uploadType="announcements"
          onChoose={media => this.onImageChoose(media)}
        />
      </>
    );
  }
}

export default AddAnnouncements;
