import React, { Component } from 'react';
import './index.scss';
import { Modal, Form, Input, Button, message, Switch, Row, Col } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { addTranslation } from '../../../../../helpers/ajax/settingsAjax';

class AddTranslationForm extends React.Component {
    state = {
        saving: false,
        rtl: this.props.modalType === 'edit_modal' && this.props.translation.translation_rtl === "1"
    }

    changeState = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { domid, translation, closeModal } = this.props;
                values.translation_rtl = values.translation_rtl ? "1" : "0";

                this.setState({ saving: true }, () => {
                    if (this.props.modalType === "add_modal") {
                        addTranslation(domid, values).then(res => {
                            this.setState({ saving: false });
                            if (!res.error) {
                                closeModal();
                            } else {
                                message.error('Could not add translation!');
                            }
                        });
                    } else {
                        addTranslation(domid, values, translation._id.$id).then(res => {
                            this.setState({ saving: false });
                            if (!res.error) {
                                closeModal();
                            } else {
                                message.error('Could not edit translation!');
                            }
                        });
                    }
                })
            }
        });
    };

    insertDuration = (field) => {
        const { translation_data } = this.props.form.getFieldsValue(['translation_data']);
        this.props.form.setFieldsValue({
            translation_data: {
                ...translation_data,
                [field]: `${translation_data[field]}[x]`
            }
        });
    };

    render() {
        const { modalType, translation } = this.props;
        const { translation_rtl, translation_name, translation_data } = translation;

        const { getFieldDecorator } = this.props.form;

        const addOn = field => <span
            className="insert-duraition"
            onClick={() => this.insertDuration(field)}>
            Insert Duration
        </span>;
        const translationInput = (field, insertAddon) => getFieldDecorator(`translation_data.${field}`, {
            initialValue: (modalType === 'edit_modal') ? translation_data[field] : ''
        })(
            <Input
                placeholder="Enter Translated Text"
                className={this.state.rtl ? 'rtl' : ''}
                addonAfter={insertAddon && addOn(field)}
            />
        )

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                    <Input.Group>
                        {getFieldDecorator('translation_rtl', {
                            initialValue: (modalType === 'edit_modal') ? (translation_rtl === '1' || translation_rtl === 1) : false,
                            valuePropName: 'checked'
                        })(
                            <Switch size="small" onChange={checked => this.changeState('rtl', checked)} />
                        )} Enabled Right To Left Language Support
                    </Input.Group>
                </Form.Item>

                <Form.Item>
                    <Input.Group>
                        <p className="label">Translation Set Name</p>
                        {getFieldDecorator('translation_name', {
                            rules: [{ required: true, message: 'Translation set name is required!' }],
                            initialValue: (modalType === 'edit_modal') ? translation_name : ''
                        })(
                            <Input placeholder="Enter Translation Set Name" />
                        )}
                    </Input.Group>
                </Form.Item>

                <Row gutter={24}>
                    <Col xs={11}>
                        <Form.Item>
                            <Input.Group>
                                <p className="label">Original Text</p>
                                <Input value="Someone" disabled={true} />
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input value="few seconds ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="few minutes ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="1 minute ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x minutes ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="1 hour ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x hours ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="1 day ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x days ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="1 month ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x months ago" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x days" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x weeks" disabled={true} />
                        </Form.Item>

                        <Form.Item>
                            <Input value="x months" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12, offset: 1 }}>
                        <Form.Item>
                            <Input.Group>
                                <p className="label">Translated Text</p>
                                {translationInput('someone')}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('few_seconds_ago')}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('few_minutes_ago')}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('1_minute_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_minutes_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('1_hour_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_hours_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('1_day_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_days_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('1_month_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_months_ago', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_days', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_weeks', true)}
                            </Input.Group>
                        </Form.Item>

                        <Form.Item>
                            <Input.Group>
                                {translationInput('x_months', true)}
                            </Input.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button className="submit-button" type="default" size="large" htmlType="submit" loading={this.state.saving}>
                        Save Translation Set
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
const WrappedAddTranslationForm = Form.create({ name: 'add_user' })(AddTranslationForm);

class TranslationsModal extends Component {
    render() {
        const { modalVisible, modalType, closeModal, domid, translation } = this.props;

        return (
            <Modal
                className="translations-modal"
                visible={modalVisible}
                onOk={closeModal}
                title={
                    <p className="ant-modal-title custom">
                        {modalType === 'add_modal' ? 'Add New Translation Set' : 'Edit Translation Set'}
                        <CloseIcon onClick={closeModal} />
                    </p>
                }
                footer={null}
                destroyOnClose={true}
                width={1000}
            >
                <WrappedAddTranslationForm modalType={modalType} closeModal={closeModal} domid={domid} translation={translation} />
            </Modal>
        );
    }
}

export default TranslationsModal;