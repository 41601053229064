import React from 'react';
import './index.scss';
import { Divider, Row, Col } from 'antd';

const Plan = (props) => {
    const { plan } = props;

    return (
        <div className="billing-plan">
            <h3 className="heading">Plan</h3>

            <Divider />

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Plan Name</h5>
                    <p className="value">{plan.plan_name}</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Billing Cycle</h5>
                    <p className="value">NA</p>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Plan Purchased On</h5>
                    <p className="value">NA</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Next Payment Date</h5>
                    <p className="value">NA</p>
                </Col>
            </Row>

            {/* <Button
                type="default"
                className="plan-btn success"
            >
                Upgrade plan
            </Button>

            <Button
                type="default"
                className="plan-btn cancel"
            >
                Cancel Subscription
            </Button> */}
        </div>
    );
};

export default Plan;