import React from 'react';
import { Col } from 'antd';
import './index.scss';
import Callout from '../../../assets/images/backgrounds/callout.svg';
import CalloutTwo from '../../../assets/images/backgrounds/callout2.svg';

const SideColumn = (props) => {
    return (
        <Col xs={0} lg={14} className="side-img-col">
            <img src={Callout} alt="callout-one" className="callout callout-1 slide-up-fade-in" />
            <img src={CalloutTwo} alt="callout-two" className="callout callout-2 slide-up-fade-in" />
            {/* <Callout /> */}
        </Col>
    );
};

export default SideColumn;