import React from 'react';
import './index.scss';
import { Table, Icon, Button, Switch, Tooltip } from 'antd';
import Avatar1 from '../../../../assets/images/icons/avatar1.svg';
import Avatar2 from '../../../../assets/images/icons/avatar2.svg';
import ClickToCopy from '../ClickToCopy';
import { ReactComponent as Delete } from '../../../../assets/images/icons/delete.svg';
import NoData from '../NoData';

const RecordsTable = (props) => {
    const {
        dataSource,
        rowSelection,
        pagination,
        handleTableChange,
        onToggleStatus,
        onDelete,
        loading,
        searchedText
    } = props;

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => <div className="details-div">
            <img src={index % 2 === 0 ? Avatar1 : Avatar2} alt="sample" />
            <p className="text">
                {typeof (text) === "string" && text.length && <span className="name">{text}</span>}
                <ClickToCopy defaultText="Copy Email" textToCopy={record.email}><span className="email">{record.email}</span></ClickToCopy>
            </p>
        </div >,
        sorter: true,
        width: 150,
    }, {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: text => typeof (text) === "string" && text.length ? text : '',
        sorter: true,
    }, {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: true,
    }, {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
            text === '1' || text === 1
                ? <p className="record-status">
                    Enabled {
                        <Switch
                            defaultChecked={true}
                            checked={text === 1 || text === '1'}
                            size="small"
                            onChange={() => onToggleStatus(record.id, 1)}
                        />
                    }
                </p>
                : <p className="record-status">
                    Disabled {
                        <Switch
                            checked={text === 1 || text === '1'}
                            size="small"
                            onChange={() => onToggleStatus(record.id, 0)}
                        />
                    }
                </p>
        )
    }, {
        title: 'Actions',
        dataIndex: 'id',
        key: 'actions',
        render: (text, record) => <Tooltip title="Delete Record"><Button type="default" className="delete-button" onClick={() => onDelete(record.id)}><Icon component={Delete} /></Button></Tooltip>
    }];
    let urlParts = [];
    if (props.domid) {
        urlParts.push("domid=" + props.domid);
    }
    if (props.campid) {
        urlParts.push("campid=" + props.campid);
    }
    return (
        <React.Fragment>
            <p style={{ textAlign: 'right' }}><a href={`/records/export_records?${urlParts.join("&")}`} target="_blank" rel="noreferrer noopener"><Icon type="cloud-download" /> Download CSV</a></p>
            <Table
                className="records-table"
                dataSource={dataSource}
                columns={columns}
                pagination={pagination}
                rowSelection={rowSelection}
                onChange={handleTableChange}
                loading={loading}
                scroll={{ x: "100%" }}
                locale={
                    searchedText.length
                        ? {
                            emptyText: <NoData
                                heading="No Records Found!"
                            />
                        }
                        : {
                            emptyText: <NoData
                                heading="No Records Found!"
                                content="Seems like records were not captured for this campaign because it is not yet complete."
                            />
                        }
                }
            />
        </React.Fragment >
    );
};

export default RecordsTable;