import React, { Component } from "react";
import "./index.scss";
import {
  Card,
  Table,
  Button,
  Icon,
  Row,
  Col,
  message,
  Modal,
  Tooltip
} from "antd";
import { ReactComponent as EditIcon } from "../../../../assets/images/icons/editReview.svg";
import { ReactComponent as DeleteReviewIcon } from "../../../../assets/images/icons/delete.svg";
import googleBusinessLogo from "../../../../assets/images/logos/google_business.jpg";
import facebookLogo from "../../../../assets/images/logos/FacebookLogo.png";
import {
  getReviewSources,
  deleteReviewSources
} from "../../../../helpers/ajax/reviewsAjax";
import GoogleReviewModal from "./ReviewSourceModals/Google";
import FBAppModal from "./ReviewSourceModals/Facebook";
import MainHeadingBar from "../../Partials/MainHeadingBar";
import NoData from "../../Partials/NoData";
import FacebookReviewModal from "./FacebookReviewModal";
import qs from "qs";
import axios from "axios";

class ReviewSources extends Component {
  state = {
    selectedRowKeys: [],
    sources: [],
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: ""
    },
    fbPages: [],
    paging: {
      pageSize: 10,
      total: 0,
      page: 1
    },
    addSourceModal: {
      visible: false
    },
    googleBusinessModal: {
      visible: false,
      id: undefined
    },
    fbAppModal: {
      visible: false
    },
    facebookReviewModal: {
      visible: false
    }
  };

  onToggleFacebookModal() {
    this.setState({
      facebookReviewModal: {
        visible: !this.state.facebookReviewModal.visible
      }
    });
  }

  toggleFBAppModal() {
    this.setState({
      fbAppModal: {
        visible: !this.state.fbAppModal.visible
      }
    });
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  fetchReviewSources() {
    getReviewSources(
      this.props.match.params.domid,
      this.state.paging.pageSize,
      (this.state.paging.page - 1) * this.state.paging.pageSize,
      "_id",
      "-1",
      this.state.filters.search
    ).then(res => this.setState(res));
  }
  getUrls() {
    axios({
      url: "/fbreviews/get_fb_pages",
      method: "POST",
      data: {
        domid: this.props.match.params.domid
      }
    })
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            fbPages: res.data.pages
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  }

  componentDidMount() {
    this.fetchReviewSources();

    const params = qs.parse(window.location.search.replace(/\?/, ""));

    if (params.fb) {
      if (params.fb === "success") {
        this.getUrls();
        this.onToggleFacebookModal();
      } else {
        message.error("There was an error connecting with Facebook!");
      }
    }
  }

  setFilter(key, value) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [key]: value
        },
        loading: true,
        paging: {
          pageSize: 10,
          total: 0,
          page: 1
        }
      },
      () => this.fetchReviewSources()
    );
  }

  handleTableChange = pagination => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState(
      {
        reviews: [],
        loading: true,
        paging: {
          ...this.state.pagination,
          page: pager.current
        }
      },
      () => this.fetchReviewSources()
    );
  };

  deleteSelectedReviewSources(ids) {
    deleteReviewSources(this.props.match.params.domid, ids)
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            sources: this.state.sources.filter(
              r => ids.indexOf(r._id["$id"]) === -1
            )
          });
          message.success("Source(s) deleted successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(e => {
        message.error(e.message);
      });
  }

  confirmDelete(ids) {
    const that = this;
    Modal.confirm({
      title: "Are you sure",
      content:
        "Are you sure you want to delete the selected review sources(s). Once deleted, review sources cannot be recovered",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        that.deleteSelectedReviewSources(ids);
      }
    });
  }

  toggleSourceModal(source, id) {
    this.setState({
      [source]: {
        ...this.state[source],
        visible: !this.state[source].visible,
        id: id ? id : undefined
      }
    });
  }

  editSource(source) {
    let key = "";
    if (source.source_type === "google") {
      key = "googleBusinessModal";
    }
    this.setState({
      [key]: {
        ...this.state[key],
        visible: true,
        id: source._id["$id"]
      }
    });
  }

  onAddSource(source) {
    const index = this.state.sources.findIndex(
      s => s._id["$id"] === source._id["$id"]
    );
    if (index >= 0) {
      this.setState({
        sources: [
          ...this.state.sources.slice(0, index),
          source,
          ...this.state.sources.slice(index + 1)
        ]
      });
    } else {
      this.setState({
        sources: [source, ...this.state.sources]
      });
    }
    let key = "";
    if (source.source_type === "google") {
      key = "googleBusinessModal";
    }
    this.toggleSourceModal(key);
  }

  chooseService(key) {
    this.setState(
      {
        addSourceModal: {
          visible: false
        }
      },
      () => this.toggleSourceModal(key)
    );
  }

  render() {
    const { selectedRowKeys, filters } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        title: "Source Name",
        dataIndex: "source_name"
      },
      {
        title: "Source Type",
        dataIndex: "source_type",
        render: text =>
          text
            .split(" ")
            .map(p => p.charAt(0).toUpperCase() + p.slice(1))
            .join(" ")
      },
      {
        title: "Status",
        dataIndex: "pool_type",
        render: (_, row) => {
          var html = "Reviews Updated";
          if (
            row.source_reviewcount === 0 &&
            row.source_lastprocessed === "2000-01-01 00:00:00"
          ) {
            html = "Fetching...";
          }
          return html;
        }
      },
      {
        title: "Number of Reviews",
        dataIndex: "source_reviewcount"
      },
      {
        title: "Actions",
        dataIndex: "id",
        width: "350px",
        render: (_, record) => (
          <div className="actions-div">
            <Tooltip title="Edit Source">
              <Button
                className="ant-btn edit-btn"
                onClick={() => this.editSource(record)}
              >
                <Icon component={EditIcon} />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Source">
              <Button
                type="danger"
                className="delete-btn"
                onClick={() => this.confirmDelete([record._id["$id"]])}
              >
                <Icon component={DeleteReviewIcon} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ];

    return (
      <div className="review-sources">
        <MainHeadingBar
          title="Review Sources"
          helpText="Here you can manage the review source."
          pageCTA={
            <span>
              <a
                href="#/"
                className="ant-btn ant-btn-default"
                onClick={() => this.toggleSourceModal("addSourceModal")}
              >
                <Icon type="plus" />
                Add Review Source
              </a>
            </span>
          }
          {...this.props}
        />
        <Card style={{ padding: "30px" }}>
          <Row style={{ marginBottom: "2.85rem" }}>
            <Col xs={12}>
              {this.state.selectedRowKeys.length > 0 && (
                <Button
                  style={{ background: "#F84B3D", color: "white" }}
                  onClick={() => this.confirmDelete(this.state.selectedRowKeys)}
                >
                  Delete Selected
                </Button>
              )}
            </Col>
          </Row>
          <Table
            rowKey={record => record._id["$id"]}
            dataSource={this.state.sources}
            columns={columns}
            pagination={{ total: this.state.total }}
            rowSelection={rowSelection}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            locale={
              filters.search.length
                ? {
                    emptyText: (
                      <NoData heading="No Review Sources Found!" content="" />
                    )
                  }
                : {
                    emptyText: <NoData heading="No Review Sources Found!" />
                  }
            }
          />
        </Card>

        <GoogleReviewModal
          {...this.props}
          source={this.state.sources.find(
            s => this.state.googleBusinessModal.id === s._id["$id"]
          )}
          onAddSource={source => this.onAddSource(source)}
          visible={this.state.googleBusinessModal.visible}
          toggleModal={() => this.toggleSourceModal("googleBusinessModal")}
        />

        <Modal
          className="add-source-modal"
          visible={this.state.addSourceModal.visible}
          title="Add New Source"
          onCancel={() => this.toggleSourceModal("addSourceModal")}
          footer={null}
        >
          <Row>
            <Col xs={{ span: 6, offset: 5 }}>
              <div
                className="source-box"
                onClick={() => this.chooseService("googleBusinessModal")}
              >
                <img src={googleBusinessLogo} alt="google business" />
                <p className="text">
                  Begin Integration <Icon type="arrow-right" />
                </p>
              </div>
            </Col>
            <Col xs={{ span: 6, offset: 3 }}>
              <Button
                className="source-box"
                // onClick={() =>
                //   (window.location.href = `/fbreviews/login_auth?domid=${this.props.match.params.domid}`)
                // }
                onClick={() => this.toggleFBAppModal()}
              >
                <img
                  src={facebookLogo}
                  alt="facebook"
                  style={{ width: "30%" }}
                />
                <p
                  style={{
                    margin: "5px 0 10px 0",
                    fontWeight: 600,
                    color: "#747474",
                    marginTop: "5px",
                    fontSize: "15px"
                  }}
                >
                  Facebook Reviews
                </p>
                <p className="text">
                  Begin Integration <Icon type="arrow-right" />
                </p>
              </Button>
            </Col>
          </Row>
        </Modal>
        <FacebookReviewModal
          {...this.props}
          onToggleFacebookModal={() => this.onToggleFacebookModal()}
          visible={this.state.facebookReviewModal.visible}
          fetchReviewSources={() => this.fetchReviewSources()}
          fbPages={this.state.fbPages}
        />
        <FBAppModal
          visible={this.state.fbAppModal.visible}
          domid={this.props.match.params.domid}
          toggleModal={() => this.toggleFBAppModal()}
        />
      </div>
    );
  }
}

export default ReviewSources;
