import Axios from "axios";

export const toggleCalloutStatus = (domid, key, status, campid) => {
    return Axios({
        url: '/campaigns/toggle_callout_status',
        method: 'post',
        data: {
            domid,
            key,
            status,
            campid
        }
    }).then((res) => {
        if (res.data.success === 1) {
            return {
                error: false,
                errorMsg: '',
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.message,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const toggleCampaignStatus = (domid, campid, status) => {
    return Axios({
        url: '/campaigns/toggle_campaign_status',
        method: 'post',
        data: {
            domid,
            campid,
            status,
        }
    }).then((res) => {
        if (res.data.success === 1) {
            return {
                error: false,
                errorMsg: '',
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.message,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const toggleCampaignArchive = (domid, campid, archived) => {
    return Axios({
        url: '/campaigns/toggle_campaign_archive',
        method: 'post',
        data: {
            domid,
            campid,
            archived,
        }
    }).then((res) => {
        if (res.data.success === 1) {
            return {
                error: false,
                errorMsg: '',
                loading: false
            }
        } else {
            return {
                error: true,
                errorMsg: res.data.message,
                loading: false
            }
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const getActiveCampaigns = (domid, order, limit, offset) => {
    return Axios({
        url: '/campaigns/get_campaigns',
        method: 'post',
        data: {
            domid,
            order,
            limit,
            offset
        }
    }).then((res) => {
        return {
            campaigns: res.data.campaigns,
            translations: res.data.translations,
            total: res.data.total,
            sort: res.data.sort,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const getArchivedCampaigns = (domid, order, limit, offset) => {
    return Axios({
        url: '/campaigns/get_archived_campaigns',
        method: 'post',
        data: {
            domid,
            order,
            limit,
            offset
        }
    }).then((res) => {
        return {
            campaigns: res.data.campaigns,
            translations: res.data.translations,
            total: res.data.total,
            sort: res.data.sort,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const getDraftCampaigns = (domid, order, limit, offset) => {
    return Axios({
        url: '/campaigns/get_draft_campaigns',
        method: 'post',
        data: {
            domid,
            order,
            limit, offset
        }
    }).then((res) => {
        return {
            campaigns: res.data.campaigns,
            translations: res.data.translations,
            total: res.data.total,
            sort: res.data.sort,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const newCampaign = (domid, campid = undefined) => {
    return Axios({
        url: '/campaigns/new_campaign',
        method: 'post',
        data: {
            domid,
            campid
        }
    })
};

export const saveCampaign = (campaign, domid) => {
    return Axios({
        url: '/campaigns/save_campaign',
        method: 'post',
        data: {
            domid,
            campaign
        }
    })
};

export const getCampaignAnalytics = (domid, campid, startDate, endDate) => {
    return Axios({
        url: '/campaigns/get_campaign_analytics',
        method: 'post',
        data: {
            domid,
            campid,
            startDate,
            endDate
        }
    }).then((res) => {
        return {
            analytics: res.data.analytics,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};