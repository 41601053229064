import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Login from "./Components/Auth/Login/";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import AddUserSignup from "./Components/Auth/AddUserSignup";
import ResetPassword from "./Components/Auth/ResetPassword";
import Signup from "./Components/Auth/Signup";
import Logout from "./Components/Auth/Logout";
import Dashboard from "./Components/Dashboard";
import Deactivated from "./Components/Dashboard/Deactivated";
import Exhausted from "./Components/Dashboard/Exhausted";
import Snapshot from "./Components/Dashboard/Snapshot";
import Records from "./Components/Dashboard/Records";
import UserReviews from "./Components/Dashboard/Reviews/UserReviews";
import ReviewPools from "./Components/Dashboard/Reviews/ReviewPools";
import ReviewSources from "./Components/Dashboard/Reviews/ReviewSources";
import Library from "./Components/Dashboard/Library";
import SearchMedia from "./Components/Dashboard/SearchMedia";
import ActiveCampaigns from "./Components/Dashboard/Campaigns/ActiveCampaigns";
import ArchivedCampaigns from "./Components/Dashboard/Campaigns/ArchivedCampaigns";
import DraftCampaigns from "./Components/Dashboard/Campaigns/DraftCampaigns";
import NewCampaign from "./Components/Dashboard/NewCampaign";
import ProfileForm from "./Components/Dashboard/ProfileSettings";
import ActivationCodes from "./Components/Dashboard/ActivationCodes";
import ManageAccounts from "./Components/Dashboard/ManageAccounts";
import Settings from "./Components/Dashboard/Settings";
import NotFound404 from "./Components/NotFound404";
import Billing from "./Components/Dashboard/Billing";
import Onboarding from "./Components/Onboarding";
import SwitchToAccount from "./Components/Dashboard/SwitchToAccount";
import Steps from "./Components/Steps";
import CampaignReport from "./Components/Dashboard/CampaignReport.js";
import Announcements from "./Components/Dashboard/Announcements";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Dashboard {...props} title="LetConvert"></Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/"
              render={props => (
                <Dashboard {...props} title="LetConvert | Snapshot">
                  <Snapshot {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/login"
              render={props => (
                <Login {...{ ...props, title: "LetConvert | Login" }} />
              )}
            />
            <Route
              path="/login/forgot/:hash"
              render={props => (
                <ResetPassword
                  {...{ ...props, title: "LetConvert | Reset Password" }}
                />
              )}
            />
            <Route
              path="/login/adduser/:hash"
              render={props => (
                <AddUserSignup
                  {...{ ...props, title: "LetConvert | Signup" }}
                />
              )}
            />
            <Route
              path="/forgot-password"
              render={props => (
                <ForgotPassword
                  {...{ ...props, title: "LetConvert | Forgot Password" }}
                />
              )}
            />
            <Route
              path="/signup"
              render={props => (
                <Signup {...{ ...props, title: "LetConvert | Signup" }} />
              )}
            />
            <Route
              path="/signup_invite"
              render={props => (
                <Signup {...{ ...props, title: "LetConvert | Signup" }} />
              )}
            />
            <Route
              path="/pitchground"
              render={props => (
                <Signup {...{ ...props, title: "LetConvert | Signup" }} />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/active_campaigns"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Active Campaigns"
                  openKeys={["submenu_campaigns"]}
                >
                  <ActiveCampaigns {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/archived_campaigns"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Archived Campaigns"
                  openKeys={["submenu_campaigns"]}
                >
                  <ArchivedCampaigns {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/draft_campaigns"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Draft Campaigns"
                  openKeys={["submenu_campaigns"]}
                >
                  <DraftCampaigns {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/new_campaign/:campid([a-fA-F0-9]{24})?"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | New Campaign"
                  openKeys={["submenu_campaigns"]}
                >
                  <NewCampaign {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/edit_campaign/:campid([a-fA-F0-9]{24})/:page([0-9])?"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Edit Campaign"
                  openKeys={["submenu_campaigns"]}
                >
                  <NewCampaign {...props} edit={true} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/records"
              render={props => (
                <Dashboard {...props} title="LetConvert | Records">
                  <Records {...props} />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/reviews/:pool?"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | User Reviews"
                  openKeys={["submenu_reviews"]}
                >
                  <UserReviews {...props} />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/review-pools"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Review Pools"
                  openKeys={["submenu_reviews"]}
                >
                  <ReviewPools {...props} />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/review-sources"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Review Sources"
                  openKeys={["submenu_reviews"]}
                >
                  <ReviewSources {...props} />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/library/search-gifs"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Search GIFs"
                  openKeys={["submenu_library"]}
                >
                  <SearchMedia {...props} type="gifs" />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/library/search-icons"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | Search Icons"
                  openKeys={["submenu_library"]}
                >
                  <SearchMedia {...props} type="icons" />
                </Dashboard>
              )}
            />

            <Route
              path="/:domid([a-fA-F0-9]{24})/library"
              render={props => (
                <Dashboard
                  {...props}
                  title="LetConvert | My Library"
                  openKeys={["submenu_library"]}
                >
                  <Library {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/settings"
              render={props => (
                <Dashboard {...props} title="LetConvert | Settings">
                  <Settings {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/announcements"
              render={props => (
                <Dashboard {...props} title="LetConvert | Announcements">
                  <Announcements {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/settings"
              render={props => (
                <Dashboard {...props} title="LetConvert | Profile Settings">
                  <ProfileForm {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/activation-codes"
              render={props => (
                <Dashboard {...props} title="LetConvert | Activation Codes">
                  <ActivationCodes {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/manage-accounts"
              render={props => (
                <Dashboard {...props} title="LetConvert | Manage Accounts">
                  <ManageAccounts {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/billing"
              render={props => (
                <Dashboard {...props} title="LetConvert | Billing">
                  <Billing {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/onboarding"
              render={props => (
                <Onboarding title="LetConvert | Onboarding" {...props} />
              )}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/steps/:stepid([0-9])?"
              render={props => (
                <Steps title="LetConvert | Onboarding" {...props} />
              )}
            />

            <Route
              path="/switch-account/:domid([a-fA-F0-9]{24})?"
              render={props => <SwitchToAccount {...props} />}
            />

            <Route
              exact
              path="/:domid([a-fA-F0-9]{24})/campaign-report/:campid([a-fA-F0-9]{24})"
              render={props => (
                <Dashboard {...props} title="LetConvert | Campaign Report">
                  <CampaignReport {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/logout"
              render={props => <Logout {...props} />}
            />

            <Route
              exact
              path="/deactivated"
              render={props => (
                <Dashboard {...props} title="LetConvert">
                  <Deactivated {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/exhausted"
              render={props => (
                <Dashboard {...props} title="LetConvert">
                  <Exhausted {...props} />
                </Dashboard>
              )}
            />

            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
