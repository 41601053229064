import React, { Component } from 'react';
import './index.scss';
import { Table, Input, Button, Icon, Tooltip } from 'antd';
import { ReactComponent as Download } from '../../../../assets/images/icons/download.svg';
import moment from 'moment';

class History extends Component {
    state = {
        searchedKeyword: ''
    };

    search = (searchedKeyword) => {
        this.setState({
            ...this.state,
            searchedKeyword
        });
    };

    render() {
        return (
            <div className="billing-history">
                <Table
                    title={() => <div className="title">
                        <h1 className="heading">History</h1>
                        <div className="search-div">
                            Search
                            <Input placeholder="Enter keywords" onChange={(e) => this.search(e.target.value)} />
                        </div>
                    </div>}
                    dataSource={[]}
                    columns={[
                        {
                            title: 'Plan Name',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: (a, b) => a.name - b.name,
                            render: text => <span className="plan-name">{text}</span>
                        },
                        {
                            title: 'Billed On',
                            dataIndex: 'billed_on',
                            key: 'billed_on',
                            sorter: (a, b) => a.billed_on - b.billed_on,
                            render: text => moment(text).format('MMM DD, YYYY HH:MM')
                        },
                        {
                            title: 'Amount',
                            dataIndex: 'amount',
                            key: 'amount',
                            render: text => `$ ${text}`
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: text => text === 1 || text === '1' ? 'Successful' : 'Unsuccessful'
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'id',
                            key: 'actions',
                            render: (text, record) => <Tooltip title="Download Invoice"><Button type="default" className="download-button">
                                <Icon component={Download} />
                            </Button></Tooltip>,
                            width: 125
                        }
                    ]}
                />
            </div>
        );
    }
};

export default History;