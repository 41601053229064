import React from 'react';
import './index.scss';
import { Modal, Button, Icon } from 'antd';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import Success from '../../../../assets/images/icons/success.png';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrowright.svg';

const SuccessModal = (props) => {
    const { visibleModal, toggleModal } = props;

    return(
        <Modal
            visible={visibleModal === "successModal"}
            closable={false}
            footer={null}
            className="success-modal"
            width={536}
        >
            <Close className="close-modal" onClick={() => toggleModal("")} />
            <img src={Success} alt="success"/>

            <h3 className="title">We’re in touch!</h3>
            <p className="description">
                We have sent an email to yout teammate with instructions to install LetConvert on your website.
            </p>

            <Button type="default" onClick={() => toggleModal("")}>
                Continue <Icon component={Arrow} />
            </Button>
        </Modal>
    );
};

export default SuccessModal;