import React, { Component } from 'react';
import './index.scss';
import { Modal, Button, Icon, Alert } from 'antd';
import WavingHand from '../../assets/images/icons/waving-hand.png';
import WinkingFace from '../../assets/images/icons/winking-face.png';
import { ReactComponent as CEO } from '../../assets/images/icons/CEO.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrowright.svg';
import { connect } from 'react-redux';
import logo from '../../assets/images/logos/logo_dark.svg';
import OnboardingForm from './OnboardingForm';
import { ReactComponent as Check } from '../../assets/images/icons/check-circle-filled.svg';
import { ReactComponent as Cross } from '../../assets/images/icons/cross-circle-filled.svg';
import { Link, Redirect } from 'react-router-dom';
import { addDomain } from '../../helpers/ajax/onboardingAjax';
import OnboardingFooter from '../Dashboard/Partials/OnboardingFooter';

class Onboarding extends Component {
    state = {
        welcomeModalVisible: true,
        successModalVisible: false,
        failureModalVisible: false,
        errorMsg: '',
    }

    constructor(props) {
        super(props);
        document.title = props.title;
    }

    toggleState = (field) => {
        this.setState({
            [field]: !this.state[field]
        })
    };

    add_domain = (dom_name, dom_domain) => {
        addDomain(dom_name, dom_domain).then(res => {
            if (res.success === '1' || res.success === 1) {
                this.toggleState("successModalVisible");
                setTimeout(() => this.props.history.push(`/switch-account/${res.id}`), 2000);
            } else {
                this.setState({
                    ...this.state,
                    errorMsg: res.reason
                }, () => this.toggleState("failureModalVisible"));
            }
        });
    };

    componentDidMount() {
        let firstAccount = true;
        const user = this.props.auth.user;
        if (user.user_domains && Object.keys(user.user_domains).length > 0) {
            firstAccount = false;
        }
        this.setState({
            welcomeModalVisible: firstAccount
        })
    }

    render() {
        const { user, logged_in } = this.props.auth;
        const plan = this.props.auth.loginplan;

        if (!logged_in) return <Redirect to='/switch-account' />;

        let firstAccount = true;
        let addAllowed = false;
        let ownerAccounts = 0;
        if (user.user_domains && Object.keys(user.user_domains).length > 0) {
            firstAccount = false;

            ownerAccounts = Object.keys(user.user_domains).reduce((prev, d) => {
                if (user.user_domains[d].role === "owner") {
                    return prev + 1;
                } else {
                    return prev;
                }
            }, 0);
        }
        if (ownerAccounts < plan.plan_features.DOM_COUNT || plan.plan_features.DOM_COUNT === -1) {
            addAllowed = true;
        }

        return (
            <React.Fragment>
                <div className="onboarding-container">
                    <span style={{ position: "absolute", top: 10, right: 10 }}><Link to="/logout">Logout</Link></span>
                    {!firstAccount && <span style={{ position: "absolute", top: 10, left: 10 }}><a href="#!" onClick={() => this.props.history.goBack()}><Icon type="arrow-left" /> Back</a></span>}
                    <div className="onboarding">
                        <img src={logo} className="logo" alt="logo" />

                        <div className="progress-bar"></div>

                        <h3 className="title">One Quick Step, Yet Very Essential</h3>
                        <p className="sub-title">Add your company name and website URL for us to set up an account for you</p>

                        {addAllowed ? <OnboardingForm add_domain={this.add_domain} /> :
                            <Alert
                                style={{ width: "100%", maxWidth: 500 }}
                                message="Error"
                                description="Your plan does not allow you to add more accounts"
                                type="error"
                                showIcon
                            />
                        }
                    </div>

                    <Modal
                        className="onboarding-modal welcome-modal"
                        visible={this.state.welcomeModalVisible}
                        footer={null}
                        closable={false}
                        width={536}
                    >
                        <CEO />

                        <h3 className="heading">
                            Welcome to LetConvert!
                    </h3>

                        <p className="para para-1">
                            <img src={WavingHand} alt="waving-hand" />​ Hey <span className="name">{user.user_fname.split(' ')[0]}</span> ! I’m Vipul, the CEO of LetConvert by LetX. I wanted to personally welcome you and take you to next steps ahead.
                    </p>

                        <p className="para para-2">
                            LetConvert makes it easier to drive your visitors to take an action that matters.
                    </p>

                        <p className="para para-3">
                            Let’s add your company domain to get you started right away! ​<img src={WinkingFace} alt="winking-face" />
                        </p>

                        <Button type="default" onClick={() => this.toggleState('welcomeModalVisible')}>
                            Let’s Go <Icon component={ArrowRight} />
                        </Button>

                        <p className="below-button">
                            Just one easy step, that’s it!
                    </p>
                    </Modal>

                    <Modal
                        className="onboarding-modal onboarding-success-modal"
                        visible={this.state.successModalVisible}
                        footer={null}
                        closable={false}
                        width={536}
                    >
                        <Check />

                        <h3 className="heading">
                            Success!
                    </h3>

                        <p className="disclaimer">
                            Company Name and Website URL added successfully! We are setting up your dashboard. You’ll be redirected in a second.
                    </p>
                    </Modal>

                    <Modal
                        className="onboarding-modal onboarding-failure-modal"
                        visible={this.state.failureModalVisible}
                        footer={null}
                        closable={false}
                        width={536}
                    >
                        <Cross />

                        <h3 className="heading">
                            Error!
                    </h3>

                        <p className="disclaimer">
                            Your account could not be set up since {this.state.errorMsg}! We suggest you recheck your entries and try again.
                    </p>

                        <Button className="below-disclaimer" type="default" onClick={() => this.toggleState('failureModalVisible')}>
                            Try Again <Icon component={ArrowRight} />
                        </Button>
                    </Modal>
                </div >
                <OnboardingFooter />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.auth
});

export default connect(mapStateToProps)(Onboarding);