import React from "react";
import { Row, Col, Select } from "antd";
import ColorPicker from "../ColorPicker";
import { get } from "lodash";
const { Option } = Select;

export default props => {
  const fonts = [
    {
      family: "Quicksand",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Quicksand:400,700" rel="stylesheet">'
    },
    {
      family: "Open Sans",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet">'
    },
    {
      family: "Montserrat",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet">'
    },
    {
      family: "Libre Baskerville",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700" rel="stylesheet">'
    },
    {
      family: "Raleway",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Raleway:400,700" rel="stylesheet">'
    },
    {
      family: "Roboto",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet">'
    },
    {
      family: "Droid Sans",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Droid+Sans:400,700" rel="stylesheet">'
    },

    {
      family: "Abril Fatface",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Abril+Fatface:400,700" rel="stylesheet">'
    },
    {
      family: "PT Sans",
      url:
        '<link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700" rel="stylesheet">'
    },
    {
      family: "Merriweather Sans",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700" rel="stylesheet">'
    },
    {
      family: "Lato",
      url:
        '<link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet">'
    }
  ];

  return (
    <div className="design-settings">
      <Row>
        <Col xs={8}>
          <ColorPicker
            label="Title Color"
            value={get(props.settings, "title", "#D40000")}
            onChange={color => props.onChange("title", color)}
          />
        </Col>
        <Col xs={8}>
          <ColorPicker
            label="Message Color"
            value={get(props.settings, "text", "#D40000")}
            onChange={color => props.onChange("text", color)}
          />
        </Col>
        <Col xs={8}>
          <ColorPicker
            label="Alert Background"
            value={get(props.settings, "background", "#ffffff")}
            onChange={color => props.onChange("background", color)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <ColorPicker
            label="Highlight Text Color"
            value={get(props.settings, "highlighttext", "#D40000")}
            onChange={color => props.onChange("highlighttext", color)}
          />
        </Col>
        <Col xs={8}>
          <ColorPicker
            label="Highlight Background Color"
            value={get(props.settings, "highlightbg", "#FFEEEE")}
            onChange={color => props.onChange("highlightbg", color)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <div className="ant-form-item-control">
            <p className="form-labels">Font</p>
            <Select
              style={{ width: 172 }}
              value={get(props.settings, "font.family", "Quicksand")}
              onChange={val => {
                props.onChange("font", fonts.filter(f => f.family === val)[0]);
              }}
            >
              {fonts.map(f => (
                <Option key={f.family} value={f.family}>
                  {" "}
                  {f.family}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col xs={8}>
          <div className="ant-form-item-control">
            <p className="form-labels">Alert Animation</p>
            <Select
              style={{ width: 172 }}
              value={get(props.settings, "animation", "zoomIn")}
              onChange={val => {
                props.onChange("animation", val);
              }}
            >
              <Option value="zoomIn">Zoom in</Option>
              <Option value="zoomInUp">Zoom in up</Option>
              <Option value="fadeIn">Fade in</Option>
              <Option value="fadeInUp">Fade in up</Option>
              <Option value="bounce">Bounce</Option>
              <Option value="flash">Flash</Option>
              <Option value="pulse">pulse</Option>
              <Option value="rubberBand">Rubberband</Option>
              <Option value="shake">Shake</Option>
              <Option value="fadeInDown">Fade in Down</Option>
              <Option value="fadeInLeft">Fade in Left</Option>
              <Option value="zoomInDown">Zoom in Down</Option>
              <Option value="rollIn">Roll In</Option>
              <Option value="swing">Swing</Option>
              <Option value="tada">Tada</Option>
              <Option value="wobble">Wobble</Option>
              <Option value="jello">Jello</Option>
              <Option value="jackInTheBox">Jack In the Box</Option>
              <Option value="lightSpeedIn">Light Speed in</Option>
              <Option value="flipInX">FlipinX</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </div>
  );
};
