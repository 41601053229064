import React, { Component } from 'react';
import './index.scss';
import { Button, Divider, Row, Col, Input, Icon, Skeleton, Alert, Select, Switch, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { getDomDetails, saveDomDetails } from '../../../../helpers/ajax/settingsAjax';

const { Option } = Select;

class AccountSettings extends Component {
    state = {
        error: false,
        errorMsg: '',
        loading: false,
        saving: false,
        dom_websites: [],
        dom_websites_errors: [],
        dom_name: '',
        dom_timezone: '',
        all_timezones: {},
        branding_on: 0,
        branding_name: "",
        branding_url: "",
        dom_settings: {},
        extra_doms: [],
        extra_doms_errors: []
    };

    changeState = (field, value) => {
        let disableBrandingAllowed = false;
        if (this.props.auth.plan.plan_features.DISABLE_BRANDING === 1 || this.props.auth.plan.plan_features.DISABLE_BRANDING === '1') {
            disableBrandingAllowed = true;
        }
        if (field === "branding_on" && !disableBrandingAllowed) {
            message.error('Your plan does not allow you to disable branding!');
            return;
        }
        this.setState({
            [field]: value
        })
    };

    componentDidMount = () => {
        const { domain } = this.props.auth;

        getDomDetails(domain.id).then(res => {
            this.setState({
                ...this.state,
                ...res
            });
        });
    };

    validateDomainNames = (value, field, array, index) => {
        if (!value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g)) {
            array[index] = true;
        } else {
            array[index] = false;
        }
        this.changeState(field, [...array]);
    };

    saveSettings = () => {
        const {
            extra_doms_errors,
            dom_websites_errors,
            dom_websites,
            extra_doms,
            dom_settings,
            dom_timezone,
            branding_on,
            branding_name,
            branding_url,
            dom_name
        } = this.state;
        if (
            [...extra_doms_errors, ...dom_websites_errors].indexOf(true) > -1 ||
            (
                !dom_websites.filter(dom => dom.trim().length > 0).length &&
                !extra_doms.filter(dom => dom.trim().length > 0).length
            )
        ) {
            message.warning('Invalid settings!');
        } else {
            dom_settings.branding.on = branding_on;
            dom_settings.branding.name = branding_name;
            dom_settings.branding.url = branding_url;
            dom_settings.dom_timezone = dom_timezone;
            this.setState({
                saving: true
            }, () => {
                saveDomDetails(this.props.auth.domain.id, dom_name, dom_settings, [...dom_websites.filter(dom => dom.trim().length > 0), ...extra_doms.filter(dom => dom.trim().length > 0)])
                    .then(res => {
                        this.setState({ saving: false })
                        if (!res.error) {
                            message.success('Settings updated!');
                            getDomDetails(this.props.auth.domain.id).then(res => {
                                this.setState({
                                    ...this.state,
                                    ...res,
                                    extra_doms: [],
                                    extra_doms_errors: []
                                });
                            });
                        } else {
                            message.error(res.errorMsg);
                        }
                    });
            })
        }
    };

    render() {
        const {
            error,
            errorMsg,
            loading,
            dom_websites,
            dom_websites_errors,
            dom_name,
            dom_timezone,
            all_timezones,
            branding_on,
            branding_name,
            branding_url,
            extra_doms,
            extra_doms_errors
        } = this.state;
        let customBrandingAllowed = false;
        if (this.props.auth.plan.plan_features.CUSTOM_BRANDING === 1 || this.props.auth.plan.plan_features.CUSTOM_BRANDING === '1') {
            customBrandingAllowed = true;
        }
        if (loading) {
            return <div style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </div>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        return (
            <div className="account-settings">
                <h3 className="heading">
                    <span className="text">Account Settings <Tooltip title="Here you can manage the settings of your account "><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></span>
                    <Button type="default" onClick={this.saveSettings} loading={this.state.saving}>Save Settings</Button>
                </h3>

                <Divider />

                <Row gutter={24}>
                    <Col xs={24} md={8} className="col-1">
                        <div className="input-container">
                            <p className="label">Website Name</p>
                            <Input disabled={true} value={dom_name} />
                        </div>

                        <div className="input-container">
                            <p className="label">Default Timezone</p>
                            <Select
                                value={dom_timezone}
                                style={{ width: '100%' }}
                                onChange={(value) => this.changeState('dom_timezone', value)}
                            >
                                {
                                    Object.keys(all_timezones)
                                        .map((timezone, i) => <Option value={`${timezone}|${all_timezones[timezone]}`} key={i}>{timezone}</Option>)
                                }
                            </Select>
                        </div>

                        <div className="input-container">
                            <p className="label">LetConvert Branding</p>
                            <p className="switch-text">
                                Enabled
                                <Switch
                                    checked={branding_on === 1}
                                    size="small"
                                    onChange={(checked) => this.changeState('branding_on', Number(checked))}
                                />
                            </p>
                        </div>
                        {branding_on === 1 &&
                            <div className="branding-settings">
                                <div className="input-container">
                                    <p className="label">Brand Name</p>
                                    <Input value={branding_name} onChange={e => this.setState({ branding_name: e.target.value })} readOnly={!customBrandingAllowed} />
                                </div>
                                <div className="input-container">
                                    <p className="label">Brand URL</p>
                                    <Input type="url" value={branding_url} onChange={e => this.setState({ branding_url: e.target.value })} readOnly={!customBrandingAllowed} />
                                </div>
                            </div>
                        }
                    </Col>
                    <Col xs={24} md={{ span: 10, offset: 6 }}>
                        <div className="input-container">
                            <p className="label">Domain Name</p>

                            {
                                dom_websites.map((name, i) => (
                                    <div key={i}>
                                        <div className="domain_name_container">
                                            <Input
                                                value={name}
                                                type="url"
                                                onChange={(e) => {
                                                    this.validateDomainNames(
                                                        e.target.value,
                                                        'dom_websites',
                                                        dom_websites_errors,
                                                        i
                                                    );
                                                    dom_websites[i] = e.target.value;
                                                    this.changeState(
                                                        'dom_websites',
                                                        [...dom_websites]
                                                    );
                                                }}
                                            />
                                            <Icon
                                                component={Close}
                                                onClick={() => {
                                                    this.changeState(
                                                        'dom_websites',
                                                        [...dom_websites.filter(dom => dom !== name)]
                                                    )
                                                }}
                                            />
                                        </div>

                                        {dom_websites_errors[i] && <p className="error">Invalid URL</p>}
                                    </div>
                                ))
                            }

                            {
                                extra_doms.map((name, i) => (
                                    <div key={i}>
                                        <div className="domain_name_container">
                                            <Input
                                                value={name}
                                                type="url"
                                                onChange={(e) => {
                                                    this.validateDomainNames(
                                                        e.target.value,
                                                        'extra_doms',
                                                        extra_doms_errors,
                                                        i
                                                    );
                                                    extra_doms[i] = e.target.value;
                                                    this.changeState(
                                                        'extra_doms',
                                                        [...extra_doms]
                                                    );
                                                }}
                                            />
                                            <Icon
                                                component={Close}
                                                onClick={() => this.changeState(
                                                    'extra_doms',
                                                    [...extra_doms.filter(dom => dom !== name)]
                                                )}
                                            />
                                        </div>

                                        {extra_doms_errors[i] && <p className="error">Invalid URL</p>}
                                    </div>
                                ))
                            }
                        </div>

                        <Button
                            className="add-domain-btn"
                            onClick={(e) => this.changeState('extra_doms', [...extra_doms, ''])}
                        >
                            <Icon type="plus" size="large" />Add New Domain
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(AccountSettings);