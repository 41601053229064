import React from 'react';
import './index.scss';
import { Row, Col, Icon, Tooltip } from 'antd';
import { ChartCard, MiniBar, MiniArea } from 'ant-design-pro/lib/Charts';
import { ReactComponent as Rocket } from '../../../../assets/images/icons/rocket.svg';
import { ReactComponent as Flag } from '../../../../assets/images/icons/flag.svg';
import { ReactComponent as Team } from '../../../../assets/images/icons/team.svg';
import { ReactComponent as Linechart } from '../../../../assets/images/icons/linechart.svg';
import { ReactComponent as InfoCircle } from '../../../../assets/images/icons/info-cirlce-o.svg';

const OverallStats = (props) => {
    let { active_campaigns, total_campaigns, total_records, unique_visitors } = props;
    const init = { total: 0, this_month: [], this_month_total: 0 };
    active_campaigns = (
        typeof (active_campaigns) === "object" &&
        Object.keys(active_campaigns).length
    ) ? {
            total: active_campaigns.total,
            this_month: Object.keys(active_campaigns.this_month).map(key => ({ x: key, y: active_campaigns.this_month[key] })),
            this_month_total: Object.values(active_campaigns.this_month).reduce((total, value) => total + value, 0)
        } : init;

    total_campaigns = (
        typeof (total_campaigns) === "object" &&
        Object.keys(total_campaigns).length
    ) ? {
            total: total_campaigns.total,
            this_month: Object.keys(total_campaigns.this_month).map(key => ({ x: key, y: total_campaigns.this_month[key] })),
            this_month_total: Object.values(total_campaigns.this_month).reduce((total, value) => total + value, 0)
        } : init;

    total_records = (
        typeof (total_records) === "object" &&
        Object.keys(total_records).length
    ) ? {
            total: total_records.total,
            this_month: Object.keys(total_records.this_month).map(key => ({ x: key, y: total_records.this_month[key] })),
            this_month_total: Object.values(total_records.this_month).reduce((total, value) => total + value, 0)
        } : init;

    unique_visitors = (
        typeof (unique_visitors) === "object" &&
        Object.keys(unique_visitors).length
    ) ? {
            ...unique_visitors,
            this_month_total: unique_visitors.this_month.reduce((total, obj) => total + obj.y, 0)
        } : init;

    return (
        <div className="overall-stats">
            <Row gutter={24}>
                <Col xs={24} md={12} lg={6} className="column">
                    <ChartCard
                        title="Total Campaigns"
                        action={
                            <Tooltip title="Total number of campaigns in this account">
                                <Icon component={InfoCircle} />
                            </Tooltip>
                        }
                        footer={
                            <p>
                                <span className="number-label">This month</span>
                                <span className="number">{total_campaigns.this_month_total}</span>
                            </p>
                        }
                    >
                        <p className="label">
                            <Icon component={Rocket} /> <span>{total_campaigns.total}</span>
                        </p>

                        <MiniArea
                            line
                            color="#FF2F2F"
                            borderColor="#FF2F2F"
                            height={20}
                            data={total_campaigns.this_month}
                        />
                    </ChartCard>
                </Col>

                <Col xs={24} md={12} lg={6} className="column">
                    <ChartCard
                        title="Active Campaigns"
                        action={
                            <Tooltip title="Total number of active campaigns in this account">
                                <Icon component={InfoCircle} />
                            </Tooltip>
                        }
                        footer={
                            <p>
                                <span className="number-label">This month</span>
                                <span className="number">{active_campaigns.this_month_total}</span>
                            </p>
                        }
                    >
                        <p className="label">
                            <Icon component={Flag} /> <span>{active_campaigns.total}</span>
                        </p>

                        <MiniArea
                            line
                            color="#FF2F2F"
                            borderColor="#FF2F2F"
                            height={20}
                            data={active_campaigns.this_month}
                        />
                    </ChartCard>
                </Col>

                <Col xs={24} md={12} lg={6} className="column">
                    <ChartCard
                        title="Engaged Visitors"
                        action={
                            <Tooltip title="No.of people who saw the alerts">
                                <Icon component={InfoCircle} />
                            </Tooltip>
                        }
                        footer={
                            <p>
                                <span className="number-label">This month</span>
                                <span className="number">{unique_visitors.this_month_total}</span>
                            </p>
                        }
                    >
                        <p className="label">
                            <Icon component={Team} /> <span>{unique_visitors.total}</span>
                        </p>

                        <MiniBar
                            line
                            color="#FF2F2F"
                            borderColor="#FF2F2F"
                            height={20}
                            data={unique_visitors.this_month}
                        />
                    </ChartCard>
                </Col>

                <Col xs={24} md={12} lg={6} className="column">
                    <ChartCard
                        title="Records Tracked"
                        action={
                            <Tooltip title="No. of leads/emails captured">
                                <Icon component={InfoCircle} />
                            </Tooltip>
                        }
                        footer={
                            <p>
                                <span className="number-label">This month</span>
                                <span className="number">{total_records.this_month_total}</span>
                            </p>
                        }
                    >
                        <p className="label">
                            <Icon component={Linechart} /> <span>{total_records.total}</span>
                        </p>

                        <MiniBar
                            line
                            color="#FF2F2F"
                            borderColor="#FF2F2F"
                            height={20}
                            data={total_records.this_month}
                        />
                    </ChartCard>
                </Col>
            </Row>
        </div>
    );
};

export default OverallStats;