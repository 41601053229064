import React from 'react';
import './index.scss';
import { Divider, Row, Col } from 'antd';
import { formatNumber } from '../../../../helpers/numbers';

const SubscriptionDetails = (props) => {
    const { plan, user } = props;
    let visitorCount = "Unlimited";
    if (plan.plan_features && plan.plan_features.VISITOR_COUNT) {
        if (plan.plan_features.VISITOR_COUNT !== -1) {
            visitorCount = plan.plan_features.VISITOR_COUNT;
        }
    }
    let codeCount = 0;
    if (plan.plan_features && plan.plan_features.CODE_COUNT) {
        if (plan.plan_features.CODE_COUNT !== -1) {
            codeCount = plan.plan_features.CODE_COUNT;
        }
    }
    let accountCount = 0;
    if (user.user_domains && Object.keys(user.user_domains).length) {
        accountCount = Object.keys(user.user_domains).reduce((prev, d) => user.user_domains[d].role === "owner" ? prev + 1 : prev, 0)
    }
    return (
        <div className="billing-subscription-details">
            <h3 className="heading">Subscription Details</h3>

            <Divider />

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Plan Name</h5>
                    <p className="value">{plan.plan_name}</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Billing Cycle</h5>
                    <p className="value">NA</p>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Number Of Codes Activated</h5>
                    <p className="value">{codeCount}</p>
                </Col>

                <Col xs={24} md={{ span: 12 }}>
                    <h5 className="label">Total Views Enabled</h5>
                    <p className="value">{formatNumber(visitorCount)}</p>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col xs={24} md={12}>
                    <h5 className="label">Number Of Accounts Enabled</h5>
                    <p className="value">{accountCount}</p>
                </Col>
            </Row>
        </div>
    );
};

export default SubscriptionDetails;