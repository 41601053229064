import React, { Component } from "react";
import { Select, Icon, Switch, Button, Alert } from "antd";
import { get } from "lodash";
import DesignSettings from "../DesignSettings";
import LibraryModal from "../../../Library/LibraryModal";
import { CDN_URL } from "../../../../../config";
import { ReactComponent as Template1Icon } from "../../../../../assets/images/callout_templates/template1.svg";
import { ReactComponent as Template2Icon } from "../../../../../assets/images/callout_templates/template2.svg";
import { ReactComponent as Template3Icon } from "../../../../../assets/images/callout_templates/template3.svg";
import AddAnnouncements from "../../../Announcements/AddAnnouncementModal";

const { Option } = Select;

class AnnouncementsAlert extends Component {
  state = {
    addAnnouncementModal: {
      visible: false
    },
    announcements: this.props.announcements,
    libraryModalVisible: false,
    libraryModalTab: "library"
  };

  toggleAddAnnouncementModal() {
    this.setState({
      addAnnouncementModal: {
        ...this.state.addAnnouncementModal,
        visible: !this.state.addAnnouncementModal.visible
      }
    });
  }
  onAddAnnouncement(res) {
    this.setState({
      addAnnouncementModal: {
        visible: false
      },
      announcements: [...this.state.announcements, res]
    });
    this.props.onUpdate("camp_announcements.announcements", [
      ...get(this.props.campaign, "camp_announcements.announcements", []),
      res._id["$id"]
    ]);
  }

  toggleLibraryModal(tab = "library") {
    this.setState({
      libraryModalVisible: !this.state.libraryModalVisible,
      libraryModalTab: tab
    });
  }
  onImageChoose(media) {
    this.setState({
      libraryModalVisible: false
    });
    this.props.onUpdate(
      "camp_announcements.staticimage.name",
      media.media_filename
    );
  }

  render() {
    return (
      <div className="power-trails-alert" style={{ position: "relative" }}>
        <div id="tab1">
          <div className="tab-header" style={{ marginBottom: 21 }}>
            <h3 className="purple">Customize Alert</h3>
            <Icon
              type="plus"
              className="right"
              onClick={() => this.props.toggle()}
              style={{
                fontSize: "24px",
                transform: "rotate(45deg)",
                cursor: "pointer"
              }}
            />
          </div>

          <p
            id="tab1"
            style={{ fontSize: "16px", lineHeight: "22px", color: "#979797" }}
          >
            Customize the alert template to suit your requirements or use our
            recommended settings & quickly launch your campaign with optimal
            setup. If you wish to reset to default and use recommended settings
            over your changes you can do that using “Set to default” button.
          </p>

          <div className="ant-form-item-control">
            <p className="form-labels">Alert Template</p>
            <Select
              placeholder="Select Template"
              className="template-select"
              style={{ width: 172 }}
              value={get(
                this.props.campaign,
                "camp_announcements.template",
                "template2"
              )}
              onChange={val => {
                this.props.onUpdate("camp_announcements.template", val);
              }}
            >
              <Option value="template3">
                <span>
                  <b>Template 1</b>
                  <br />
                  <Template3Icon style={{ maxWidth: "100%" }} />
                </span>
              </Option>
              <Option value="template1">
                <span>
                  <b>Template 2</b>
                  <br />
                  <Template1Icon style={{ maxWidth: "100%" }} />
                </span>
              </Option>
              <Option value="template2">
                <span>
                  <b>Template 3</b>
                  <br />
                  <Template2Icon style={{ maxWidth: "100%" }} />
                </span>
              </Option>
            </Select>
          </div>
          {this.props.errors.announcements && (
            <Alert
              message={this.props.errors.announcements}
              style={{ margin: 0 }}
              type="error"
              showIcon
            />
          )}
          <div className="ant-form-item-control">
            <p className="form-labels">
              Announcements{" "}
              <a
                href="#!"
                onClick={() => this.toggleAddAnnouncementModal()}
                style={{ marginLeft: "13px", fontSize: "14px" }}
              >
                Add Announcement
              </a>
            </p>
            <Select
              placeholder="Select Announcement"
              mode="multiple"
              className="user-review-alert-review-select"
              style={{ width: "100%" }}
              value={get(
                this.props.campaign,
                "camp_announcements.announcements",
                []
              )}
              onChange={val => {
                const id = val[val.length - 1];
                let changes = [];
                changes.push({
                  path: "camp_announcements.announcements",
                  value: val
                });
                let announcement = this.state.announcements.filter(
                  r => r._id["$id"] === id
                );
                if (announcement.length) {
                  announcement = announcement[0];
                }
                if (announcement) {
                  changes.push({
                    path: "camp_announcements.previewdata.image",
                    value: announcement.announcement_image
                  });
                  changes.push({
                    path: "camp_announcements.previewdata.title",
                    value: announcement.announcement_title
                  });
                  changes.push({
                    path: "camp_announcements.previewdata.message",
                    value: announcement.announcement_message
                  });
                  changes.push({
                    path: "camp_announcements.previewdata.cta",
                    value: announcement.announcement_click
                  });
                }
                this.props.onUpdate(changes);
              }}
            >
              {this.state.announcements.map(r => (
                <Option value={r._id["$id"]} key={r._id["$id"]}>
                  <div className="announcement-preview">
                    <div
                      className="announcement-previewimage"
                      style={{
                        background: `url(${CDN_URL}/announcements/${r.announcement_image})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                      }}
                    ></div>
                    <div className="announcement-content">
                      <p className="announcement_title">
                        {r.announcement_title}
                      </p>
                      <p className="announcement_message">
                        {r.announcement_message}
                      </p>
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div id="tab2">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">General Settings</h3>
            <span
              className="right"
              style={{ color: "red", cursor: "pointer", fontSize: 14 }}
              onClick={() =>
                this.props.resetGeneralSettings("camp_announcements")
              }
            >
              Set To Default
            </span>
          </div>
          <p
            style={{
              color: "#F5B036",
              padding: "20px 0 0 0",
              fontSize: "14px",
              lineHeight: "22px"
            }}
          >
            <Icon type="exclamation-circle" /> We recommend keeping the default
            values set for optimal performance. You can always switch back to
            default settings if you do not want to keep your changed settings by
            clicking “Set To Default”.
          </p>

          <div className="ant-form-item-control">
            <p className="form-labels">
              Show alert only once
              <Switch
                size="small"
                className="switch-green"
                checked={
                  parseInt(
                    get(this.props.campaign, "camp_announcements.showonce", 0)
                  ) === 0
                    ? false
                    : true
                }
                onChange={checked => {
                  this.props.onUpdate(
                    "camp_announcements.showonce",
                    checked ? 1 : 0
                  );
                }}
              />
            </p>
          </div>
        </div>

        <div id="tab3">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">Design</h3>
            <span
              className="right"
              style={{ color: "red", cursor: "pointer", fontSize: 14 }}
              onClick={() => this.props.resetDesign("camp_announcements")}
            >
              Set To Default
            </span>
          </div>
          <DesignSettings
            settings={get(this.props.campaign, "camp_announcements.design", {})}
            onChange={(k, v) =>
              this.props.onUpdate(`camp_announcements.design.${k}`, v)
            }
          />
        </div>

        <div className="ant-form-item-control">
          <Button
            className="btn-green"
            onClick={() => this.props.onClickContinue()}
            size="large"
          >
            Continue <Icon type="arrow-right" />
          </Button>
          <br />
          <br />
        </div>
        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          tab={this.state.libraryModalTab}
          visible={this.state.libraryModalVisible}
          uploadType="campaigns"
          onChoose={media => this.onImageChoose(media)}
        />
        <AddAnnouncements
          {...this.props}
          onAddAnnouncement={res => this.onAddAnnouncement(res)}
          visible={this.state.addAnnouncementModal.visible}
          toggle={() => this.toggleAddAnnouncementModal()}
        ></AddAnnouncements>
      </div>
    );
  }
}

export default AnnouncementsAlert;
