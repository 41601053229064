import React, { Component } from 'react';
import { Button, Select, Switch, Input } from 'antd';
import { get } from 'lodash';
import './index.scss';
const { Option } = Select;

class CampaignSettingsTab extends Component {
  componentWillReceiveProps() {
    this.setState();
  }

  render() {
    return (
      <div className="campaign-tab campaign-settings-tab">
        <div className="tab-header" style={{ marginBottom: 5 }}>
          <h3 className="purple">Settings</h3>
        </div>
        <div className="tab-body">
          <div className="ant-form-item-control">
            <p className="form-labels">Alert Position</p>
            <Select
              placeholder="Select Alert Position"
              style={{ width: 320 }}
              defaultValue={get(this.props.campaign, "camp_calloutdesign.position", "bottom-left")}
              onChange={(val) => { this.props.onUpdate("camp_calloutdesign.position", val) }} >
              <Option value="bottom-left">Bottom Left</Option>
              <Option value="bottom-right">Bottom Right</Option>
              <Option value="top-left">Top Left</Option>
              <Option value="top-right">Top Right</Option>
            </Select>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.camp_repeat ? 'has-error' : ''}`}>
            <p className="form-labels">Alert Repeat Control</p>
            <Select
              placeholder="Alert repeat pattern"
              style={{ width: 320 }}
              defaultValue={get(this.props.campaign, "camp_settings.repeat.type", "REFRESH")}
              onChange={(val) => { this.props.onUpdate("camp_settings.repeat.type", val) }}>
              <Option value="REFRESH">On Refresh</Option>
              <Option value="DURATION">After X hours/days/weeks</Option>
            </Select>
            {get(this.props.campaign, "camp_settings.repeat.type", "REFRESH") === "DURATION" &&
              <Input
                type="number"
                defaultValue={get(this.props.campaign, "camp_settings.repeat.duration", 7)}
                onChange={(e) => { this.props.onUpdate("camp_settings.repeat.duration", e.target.value) }}
                addonAfter={
                  <Select
                    style={{ width: 96, height: 32 }}
                    defaultValue={get(this.props.campaign, "camp_settings.repeat.unit", "days")}
                    onChange={(val) => { this.props.onUpdate("camp_settings.repeat.unit", val) }}>
                    <Option value="hours">Hour(s)</Option>
                    <Option value="days">Day(s)</Option>
                    <Option value="weeks">Week(s)</Option>
                  </Select>}
                style={{ width: 200, marginLeft: 30, height: 32, verticalAlign: "middle" }} />
            }
            {this.props.errors.camp_repeat && <div className="ant-form-explain">{this.props.errors.camp_repeat}</div>}
          </div>

          <div className="ant-form-item-control"><p className="form-labels">Customise Alert Frequency <br /></p></div>
          <div className="ant-form-item-control" style={{ paddingLeft: 30 }}>
            <p className="form-labels" style={{fontWeight: 400}}> Delay before 1st alert
            <Input
                type="number"
                defaultValue={get(this.props.campaign, "camp_settings.timing.startdelay", 1)}
                onChange={(e) => { this.props.onUpdate("camp_settings.timing.startdelay", e.target.value) }}
                addonAfter={<span>Seconds</span>}
                style={{ width: 200, marginLeft: 30, height: 32, verticalAlign: "middle" }} />
            </p>
          </div>

          <div className="ant-form-item-control" style={{ paddingLeft: 30 }}>
            <p className="form-labels" style={{fontWeight: 400}}>Display each alert for
            <Input
                type="number"
                defaultValue={get(this.props.campaign, "camp_settings.timing.displayduration", 7)}
                onChange={(e) => { this.props.onUpdate("camp_settings.timing.displayduration", e.target.value) }}
                addonAfter={<span>Seconds</span>}
                style={{ width: 200, marginLeft: 30, height: 32, verticalAlign: "middle" }} />
            </p>
          </div>

          <div className="ant-form-item-control" style={{ paddingLeft: 30 }}>
            <p className="form-labels" style={{fontWeight: 400}}>Spacing between alerts
            <Input
                type="number"
                defaultValue={get(this.props.campaign, "camp_settings.timing.displaydelay", 4)}
                onChange={(e) => { this.props.onUpdate("camp_settings.timing.displaydelay", e.target.value) }}
                addonAfter={<span>Seconds</span>}
                style={{ width: 200, marginLeft: 30, height: 32, verticalAlign: "middle" }} />
            </p>
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels">Hide Alerts On Mobile
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_settings.hidemobile", 0)) === 0 ? false : true}
                onChange={(checked) => {
                  let changes = [
                    { path: "camp_settings.hidemobile", value: checked ? 1 : 0 },
                    { path: "camp_settings.mobileoptimized", value: checked ? 0 : 1 }
                  ];
                  this.props.onUpdate(changes)
                }
                } />
            </p>
          </div>

          <div className={`ant-form-item-control ${parseInt(get(this.props.campaign, "camp_settings.hidemobile", 0)) === 0 ? '' : 'disabled'}`} style={{ display: parseInt(get(this.props.campaign, "camp_settings.hidemobile", 0)) === 1 ? 'none' : 'block' }}>
            <p className="form-labels">Show Alerts On Mobile At</p>
            <Select
              placeholder="Select Alert Position"
              style={{ width: 320 }}
              defaultValue={get(this.props.campaign, "camp_calloutdesign.mobileposition", "bottom")}
              onChange={(val) => { this.props.onUpdate("camp_calloutdesign.mobileposition", val) }}>
              <Option value="bottom">Bottom</Option>
              <Option value="top">Top</Option>
            </Select>
          </div>

          <div className="ant-form-item-control" style={{ display: parseInt(get(this.props.campaign, "camp_settings.hidemobile", 0)) === 1 ? 'none' : 'block' }}>
            <p className="form-labels">Optimize For Mobile View
              <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_settings.mobileoptimized", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_settings.mobileoptimized", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className="ant-form-item-control">
            <Button className="btn-green" size="large" style={{ width: 268 }} onClick={() => this.props.save()} loading={this.props.saving}>Save Settings</Button>
            {!this.props.edit && <Button className="btn-red" size="large" style={{ width: 268, marginLeft: 15, maxHeight: 40 }} onClick={() => this.props.draft()} loading={this.props.saving}>Save Draft</Button>}
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignSettingsTab;