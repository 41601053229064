import React, { Component } from 'react';
import { Input, Select, Icon, Switch, Button } from 'antd';
import { get } from 'lodash';
import FormattingField from '../../../Partials/FormattingField';
import DesignSettings from '../DesignSettings';
import LibraryModal from '../../../Library/LibraryModal';
import { ReactComponent as Template1Icon } from '../../../../../assets/images/callout_templates/template1.svg';
import { ReactComponent as Template2Icon } from '../../../../../assets/images/callout_templates/template2.svg';
import { ReactComponent as Template3Icon } from '../../../../../assets/images/callout_templates/template3.svg';
import { CDN_URL } from '../../../../../config';
const { Option } = Select;

class RecentActivityAlert extends Component {

  state = {
    libraryModalVisible: false,
    libraryModalTab: 'upload',
    atleastOneIntegrationOn: false
  }

  setIntegrationsState(props) {
    let atleastOneIntegrationOn = false;
    const integrations = get(props.campaign, "camp_integrations", {});
    if (Object.keys(integrations).length) {
      Object.keys(integrations).forEach((i) => {
        if (parseInt(integrations[i].on) === 1) {
          if (!atleastOneIntegrationOn) {
            atleastOneIntegrationOn = true;
          }
        }
      })
    }
    this.setState({
      atleastOneIntegrationOn
    })
  }

  componentWillReceiveProps(props) {
    this.setIntegrationsState(props)
  }

  componentDidMount() {
    this.setIntegrationsState(this.props)
  }

  toggleLibraryModal(tab = 'library') {
    this.setState({
      libraryModalVisible: !this.state.libraryModalVisible,
      libraryModalTab: tab
    })
  }
  onImageChoose(media) {
    this.setState({
      libraryModalVisible: false
    });
    this.props.onUpdate("camp_recentactivity.settings.staticimage.image", media.media_filename);
  }

  render() {
    const integrationNames = {
      "aweber": "Aweber",
      "active_campaign": "Active Campaign",
      "getresponse": "GetResponse",
      "mailchimp": "MailChimp",
      "mailerlite": "MailerLite",
      "icontact": "iContact",
      "sendlane": "SendLane",
      "gotowebinar": "GoToWebinar",
      "fullcontact": "FullContact",
      "sgautoresponder": "SGAutoresponder",
      "everwebinar": "Everwebinar",
      "klaviyo": "Klaviyo",
      "convertkit": "ConvertKit",
      "drip": "Drip",
      "perkzilla": "Perkzilla",
      "leadlovers": "LeadLovers",
      "infusionsoft": "Infusionsoft",
      "mailwizz": "MailWizz",
      "mautic": "Mautic",
      "customerly": "Customer.ly"
    }
    return (
      <div className="recent-activity-alert" style={{ position: "relative" }}>
        <div id="tab1">
          <div className="tab-header" style={{ marginBottom: 21 }}>
            <h3 className="purple">Customize Alert</h3>
            <Icon
              type="plus"
              className="right"
              onClick={() => this.props.toggle()}
              style={{ fontSize: "24px", transform: "rotate(45deg)", cursor: "pointer" }}
            />
          </div>
          <p style={{ fontSize: "16px", lineHeight: "22px", color: "#979797" }}>Customize the alert template to suit your requirements or use our recommended settings & quickly launch your campaign with optimal setup. If you wish to reset to default and use recommended settings over your changes you can do that using “Set to default” button.</p>

          <FormattingField
            label="Alert Title"
            error={this.props.errors.recentactivity_title}
            field={(props) => <Input placeholder="[first_name] from [place]" {...props} style={{ maxWidth: 340 }} />}
            personalizations={[
              { tag: '[first_name]', title: 'First Name' },
              { tag: '[last_name]', title: 'Last Name' },
              { tag: '[full_name]', title: 'Full Name' },
              { tag: '[place]', title: 'Place' },
            ]}
            defaultValue={get(this.props.campaign, "camp_recentactivity.title", "")}
            onChange={(val) => this.props.onUpdate("camp_recentactivity.title", val)} />

          <FormattingField
            label="Alert Message"
            error={this.props.errors.recentactivity_message}
            field={(props) => <Input placeholder="recently booked an appointment" {...props} style={{ maxWidth: 340 }} />}
            personalizations={[
              { tag: '[first_name]', title: 'First Name' },
              { tag: '[last_name]', title: 'Last Name' },
              { tag: '[full_name]', title: 'Full Name' },
              { tag: '[place]', title: 'Place' },
            ]}
            defaultValue={get(this.props.campaign, "camp_recentactivity.message", "")}
            onChange={(val) => this.props.onUpdate("camp_recentactivity.message", val)} />

          <div className="ant-form-item-control">
            <p className="form-labels">Alert Template</p>
            <Select
              placeholder="Select Template"
              className="template-select"
              style={{ width: 172 }}
              defaultValue={get(this.props.campaign, "camp_recentactivity.template", "template2")}
              onChange={(val) => { this.props.onUpdate("camp_recentactivity.template", val) }} >
              <Option value="template1"><span><b>Template 1</b> <br /> <Template1Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template2"><span><b>Template 2</b> <br /> <Template2Icon style={{ maxWidth: "100%" }} /></span></Option>
              <Option value="template3"><span><b>Template 3</b> <br /> <Template3Icon style={{ maxWidth: "100%" }} /></span></Option>
            </Select>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.recentactivity_click ? 'has-error' : ''}`}>
            <p className="form-labels">What happens when visitor clicks the alert</p>
            <Select
              style={{ width: 172 }}
              defaultValue={get(this.props.campaign, "camp_recentactivity.settings.click.type", "no_action")}
              onChange={(val) => { this.props.onUpdate("camp_recentactivity.settings.click.type", val) }} >
              <Option value="no_action" selected="">No Action</Option>
              <Option value="redirect">Redirect in same tab</Option>
              <Option value="redirect_newtab">Redirect in new tab</Option>
              <Option value="jscode" data-label="JS Code">Trigger JS Code</Option>
              <Option value="yt_embed" data-label="Youtube Embed Code">Youtube Embed</Option>
            </Select>

            {
              (get(this.props.campaign, "camp_recentactivity.settings.click.type", "no_action") === "redirect" ||
                get(this.props.campaign, "camp_recentactivity.settings.click.type", "no_action") === "redirect_newtab") &&
              <Input
                placeholder="Enter Redirect URL"
                type="url"
                defaultValue={get(this.props.campaign, "camp_recentactivity.settings.click.url", "")}
                onChange={(e) => { this.props.onUpdate("camp_recentactivity.settings.click.url", e.target.value) }}
                style={{ maxWidth: 340, marginLeft: 20 }}
              />
            }

            {
              (get(this.props.campaign, "camp_recentactivity.settings.click.type", "no_action") === "jscode" ||
                get(this.props.campaign, "camp_recentactivity.settings.click.type", "no_action") === "yt_embed") &&
              <Input.TextArea
                placeholder=""
                type="url"
                defaultValue={get(this.props.campaign, "camp_recentactivity.settings.click.code", "")}
                onChange={(e) => { this.props.onUpdate("camp_recentactivity.settings.click.code", e.target.value) }}
                style={{ maxWidth: 340, marginLeft: 20, verticalAlign: 'top' }}
              />
            }
            {this.props.errors.recentactivity_click && <div className="ant-form-explain">{this.props.errors.recentactivity_click}</div>}
          </div>

        </div>

        <div id="tab2">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">General Settings</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetGeneralSettings('camp_recentactivity')}>Set To Default</span>
          </div>
          <p style={{ color: "#F5B036", padding: "20px 0 0 0", fontSize: "14px", lineHeight: "22px" }}><Icon type="exclamation-circle" /> We recommend keeping the default values set for optimal performance. You can always switch back to default settings if you do not want to keep your changed settings by clicking “Set To Default”.</p>

          <div className="ant-form-item-control">
            <p className="form-labels">Display maps instead of custom icons
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.onlymaps", 0)) === 0 ? false : true}
                onChange={(checked) => {
                  let changes = [{ path: "camp_recentactivity.settings.onlymaps", value: checked ? 1 : 0 }];
                  if (checked) {
                    changes.push({ path: "camp_recentactivity.settings.staticimage.enabled", value: 0 });
                  } else {
                    changes.push({ path: "camp_recentactivity.settings.staticimage.enabled", value: 1 });
                  }
                  this.props.onUpdate(changes);
                }} />
            </p>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.recentactivity_image ? 'has-error' : ''}`}>
            <p className="form-labels">Display Custom Image/GIF
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.staticimage.enabled", 0)) === 0 ? false : true}
                onChange={(checked) => {
                  let changes = [{ path: "camp_recentactivity.settings.staticimage.enabled", value: checked ? 1 : 0 }];
                  if (checked) {
                    changes.push({ path: "camp_recentactivity.settings.onlymaps", value: 0 });
                  } else {
                    changes.push({ path: "camp_recentactivity.settings.onlymaps", value: 1 });
                  }
                  this.props.onUpdate(changes);
                }} />
            </p>
          </div>
          {parseInt(get(this.props.campaign, "camp_recentactivity.settings.staticimage.enabled", 0)) === 1 &&
            <div className="upload-actions">
              {get(this.props.campaign, "camp_recentactivity.settings.staticimage.image", "").length > 0 &&
                <div className="upload-action">
                  <img src={`${CDN_URL}/campaigns/${get(this.props.campaign, "camp_recentactivity.settings.staticimage.image")}`} alt="" style={{ width: '100%', height: '100%' }} />
                </div>
              }
              <div className="upload-action" onClick={() => this.toggleLibraryModal('library')}>
                <p>
                  <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
                  Library
                </p>
              </div>
              <div className="upload-action" onClick={() => this.toggleLibraryModal('upload')}>
                <p>
                  <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                  Upload
                </p>
              </div>
            </div>
          }
          {this.props.errors.recentactivity_image && <div className="ant-form-explain" style={{ color: "red" }}>{this.props.errors.recentactivity_image}</div>}

          <div className="ant-form-item-control">
            <p className="form-labels">Do not show the visitors alerts with their own name
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.hideownconversions", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_recentactivity.settings.hideownconversions", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels">Do not show anonymous conversion alerts
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.hideanonymous", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_recentactivity.settings.hideanonymous", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels">Show each alert only once
            <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.showonce", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_recentactivity.settings.showonce", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className={`ant-form-item-control ${this.props.errors.recentactivity_numrecords ? 'has-error' : ''}`}>
            <p className="form-labels">Display recent
            <Input
                type="number"
                min={1}
                style={{ maxWidth: 50, marginLeft: 5, verticalAlign: "middle" }}
                value={get(this.props.campaign, "camp_recentactivity.num_records", 2)}
                onChange={(e) => { this.props.onUpdate("camp_recentactivity.num_records", e.target.value) }} /> records only
            </p>
            {this.props.errors.recentactivity_numrecords && <div className="ant-form-explain">{this.props.errors.recentactivity_numrecords}</div>}
          </div>

          <div className={`ant-form-item-control ${this.props.errors.recentactivity_minconversions ? 'has-error' : ''}`}>
            <p className="form-labels">Show alerts only when conversions are more than
            <Input
                type="number"
                min={1}
                style={{ maxWidth: 50, marginLeft: 18, verticalAlign: "middle" }}
                value={get(this.props.campaign, "camp_recentactivity.settings.minconversions", 2)}
                onChange={(e) => { this.props.onUpdate("camp_recentactivity.settings.minconversions", e.target.value) }} />
            </p>
            {this.props.errors.recentactivity_minconversions && <div className="ant-form-explain">{this.props.errors.recentactivity_minconversions}</div>}
          </div>

          <div className={`ant-form-item-control ${this.props.errors.recentactivity_hideoldalerts ? 'has-error' : ''}`}>
            <p className="form-labels" style={{ display: "inline-block" }}>Do not show records older than</p>
            <Input
              min={1}
              type="number"
              value={parseInt(get(this.props.campaign, "camp_recentactivity.settings.hideoldalerts.data", 7))}
              onChange={(e) => { this.props.onUpdate("camp_recentactivity.settings.hideoldalerts.data", e.target.value) }}
              style={{ maxWidth: 150, marginLeft: 18, verticalAlign: "middle" }}
              addonAfter={
                <Select
                  defaultValue={get(this.props.campaign, "camp_recentactivity.settings.hideoldalerts.unit", "days")}
                  onChange={(val) => { this.props.onUpdate("camp_recentactivity.settings.hideoldalerts.unit", val) }} >
                  <Option value="days">Days</Option>
                  <Option value="weeks">Weeks</Option>
                  <Option value="months">Months</Option>
                </Select>
              } />
            {this.props.errors.recentactivity_hideoldalerts && <div className="ant-form-explain">{this.props.errors.recentactivity_hideoldalerts}</div>}
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels">Enable Integrations
            <Switch
                size="small"
                className="switch-green"
                checked={this.state.atleastOneIntegrationOn}
                onChange={checked => this.setState({ atleastOneIntegrationOn: checked })} />
            </p>
          </div>
          {this.state.atleastOneIntegrationOn &&
            <div className="integrations" style={{ padding: "0 0 0 1.5rem" }}>
              {Object.keys(this.props.integrations).map((i) => (
                <div className={`ant-form-item-control ${this.props.errors.recentactivity_integrations && get(this.props.campaign, `camp_integrations.${i}.list`, "") === "" ? 'has-error' : ''}`} key={i}>
                  <p className="form-labels" style={{ display: "inline-block" }}>{integrationNames[i]}
                    <Switch
                      size="small"
                      className="switch-green"
                      checked={parseInt(get(this.props.campaign, `camp_integrations.${i}.on`, 0)) === 0 ? false : true}
                      onChange={(checked) => { this.props.onUpdate(`camp_integrations.${i}.on`, checked ? 1 : 0) }} />
                  </p>
                  {parseInt(get(this.props.campaign, `camp_integrations.${i}.on`, 0)) === 1 &&
                    <div style={{ display: "inline-block" }}>
                      <Select
                        style={{ width: 200, marginLeft: 50 }}
                        value={get(this.props.campaign, `camp_integrations.${i}.list`)}
                        onChange={(val) => { this.props.onUpdate(`camp_integrations.${i}.list`, val) }}>
                        {this.props.integrations[i].lists.map((l) => <Option value={l.list_id} key={l.list_id}>{l.list_name}</Option>)}
                      </Select>
                      <Icon type={this.props.integrations[i].loading ? 'loading' : 'reload'} style={{ cursor: "pointer", marginLeft: 15 }} onClick={() => this.props.refreshIntegration(i)} />
                    </div>
                  }
                </div>
              ))}
            </div>
          }
          {this.props.errors.recentactivity_integrations && <div className="ant-form-item-control has-error"><div className="ant-form-explain">{this.props.errors.recentactivity_integrations}</div></div>}
        </div>

        <div id="tab3">
          <div className="tab-header" style={{ marginTop: 40 }}>
            <h3 className="purple">Design</h3>
            <span className="right" style={{ color: "red", cursor: "pointer", fontSize: 14 }} onClick={() => this.props.resetDesign("camp_recentactivity")}>Set To Default</span>
          </div>
          <DesignSettings settings={get(this.props.campaign, "camp_recentactivity.design", {})} onChange={(k, v) => this.props.onUpdate(`camp_recentactivity.design.${k}`, v)} />
        </div>

        <div className="ant-form-item-control">
          <Button className="btn-green" onClick={() => this.props.onClickContinue()} size="large">Continue <Icon type="arrow-right" /></Button>
          <br /><br />
        </div>
        <LibraryModal
          {...this.props}
          onCancel={() => this.toggleLibraryModal()}
          tab={this.state.libraryModalTab}
          visible={this.state.libraryModalVisible}
          uploadType="campaigns"
          onChoose={(media) => this.onImageChoose(media)} />
      </div>
    )
  }
}

export default RecentActivityAlert;