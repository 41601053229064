import React, { Component } from "react";
import { Modal, Input, Button } from "antd";
import "./index.scss";
import axios from "axios";

class FacebookReviewModal extends Component {
  state = {
    source: {
      client_id: "",
      client_secret: ""
    },
    edit: false,
    errors: {},
    loading: true
  };

  componentDidMount() {
    axios({
      url: "/fbreviews/get_fb_app_data",
      method: "POST",
      data: {
        domid: this.props.domid
      }
    })
      .then(res => {
        this.setState({ loading: false });
        return res;
      })
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            source: res.data.source
          });
        }
      })
      .catch(e => {});
  }

  constructor(props) {
    super(props);
    this.fbForm = React.createRef();
  }

  resetModal(source = {}) {
    this.setState({
      source: {
        client_id: source.client_id ? source.client_id : "",
        client_secret: source.client_secret ? source.client_secret : ""
      }
    });
  }

  setField(field, value) {
    this.setState({
      source: {
        ...this.state.source,
        [field]: value
      }
    });
  }

  saveSource() {
    const source = this.state.source;
    let errors = {};
    if (source.client_id.trim().length === 0) {
      errors["client_id"] = "This is a required field";
    }
    if (source.client_secret.trim().length === 0) {
      errors["client_secret"] = "This is a required field";
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors
      });
    } else {
      this.fbForm.current.submit();
    }
  }

  render() {
    return (
      <Modal
        className="google-review-modal"
        visible={this.props.visible}
        onCancel={() => this.props.toggleModal()}
        title={
          <>
            Setup Facebook App <br />
            <p style={{ fontSize: 14, margin: "10px 0 0 0" }}>
              <a
                href="https://help.letx.co/portal/en/kb/articles/facebook-app-creation-guide-for-letconvert-28-5-2021"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to Setup a Facebook App to Fetch Reviews?
              </a>
            </p>
          </>
        }
        footer={null}
      >
        <form
          ref={this.fbForm}
          onSubmit={this.saveSource}
          method="post"
          action={`/fbreviews/login_auth?domid=${this.props.domid}`}
        >
          <div className="add-source-form">
            <div
              className={`ant-form-item-control ${
                this.state.errors.client_id ? "has-error" : ""
              }`}
            >
              <label>Facebook App Client Id </label>
              <Input
                name="client_id"
                placeholder="Enter Facebook Client ID"
                value={this.state.source.client_id}
                onChange={e => this.setField("client_id", e.target.value)}
              />
            </div>
            <div
              className={`ant-form-item-control ${
                this.state.errors.client_secret ? "has-error" : ""
              }`}
            >
              <label>Facebook App Client Secret </label>
              <Input
                name="client_secret"
                placeholder="Enter Facebook Client Secret"
                value={this.state.source.client_secret}
                onChange={e => this.setField("client_secret", e.target.value)}
              />
            </div>
            <div className="ant-form-item-control">
              <Button
                className="submit-btn"
                size="large"
                onClick={() => this.saveSource()}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

export default FacebookReviewModal;
