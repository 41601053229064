import Axios from "axios";

export const getAnnouncement = (domid, limit, offset, searchedText) => {
  return Axios({
    url: "/announcements/get_announcements",
    method: "post",
    data: {
      domid,
      limit,
      offset,
      searchedText
    }
  })
    .then(res => {
      return {
        announcements: res.data.announcements,
        total: res.data.total,
        loading: false
      };
    })
    .catch(e => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false
      };
    });
};

export const addAnnouncement = (domid, announcement) => {
  return Axios({
    url: "/announcements/add_announcements",
    method: "post",
    data: {
      domid,
      ...announcement
    }
  });
};

export const deleteAnnouncement = (domid, ids) => {
  return Axios({
    url: "/announcements/delete_announcements",
    method: "post",
    data: {
      domid,
      ids
    }
  }).then(res => {
    return res;
  });
};
