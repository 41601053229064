import React, { Component } from 'react';
import './index.scss';
import CampaignCard from '../Partials/CampaignCard';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Row, Col, Modal, Skeleton, Alert, Button, Dropdown, Icon, Menu, Card, message, Pagination } from 'antd';
import { connect } from 'react-redux';
import { getArchivedCampaigns, toggleCalloutStatus, toggleCampaignStatus, toggleCampaignArchive } from '../../../helpers/ajax/campaignAjax';
import NoData from '../Partials/NoData';
import { ReactComponent as Placeholder } from '../../../assets/images/icons/no-data.svg';

class ArchivedCampaigns extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        campaigns: [],
        sort: { camp_created: -1 },
        total: 0,
        paging: {
            pageSize: 10,
            current: 1,
        }
    };

    fetch = () => {
        getArchivedCampaigns(this.props.auth.domain.id, this.state.sort, this.state.paging.pageSize, ((this.state.paging.current - 1) * this.state.paging.pageSize)).then(res => {
            this.setState({
                ...this.state,
                ...res
            });
            window.let_convert.translations = res.translations;
        });
    };

    goToPage(page) {
        this.setState({
            paging: {
                ...this.state.paging,
                current: page
            }
        }, () => this.fetch())
    }

    componentDidMount = () => {
        const { domain } = this.props.auth;
        this.fetch(domain.id);
    };

    toggleCalloutStatus = (key, status, campid) => {
        const { domain } = this.props.auth;
        Modal.confirm({
            title: 'Are you sure?',
            content: 'This will change the status of the selected callout',
            okText: 'Confirm',
            onOk: () => toggleCalloutStatus(domain.id, key, !status, campid).then((res) => {
                if (res.error) {
                    message.error(res.errorMsg)
                } else {
                    this.fetch(domain.id);
                }
            })
        });
    };

    toggleCampaignStatus = (campid, status) => {
        const { domain } = this.props.auth;

        Modal.confirm({
            title: 'Are you sure?',
            content: 'This will change the status of this campaign',
            okText: 'Confirm',
            onOk: () => toggleCampaignStatus(domain.id, campid, !status).then(() => {
                this.fetch(domain.id);
            })
        });
    };

    toggleCampaignArchive = (campid, archived) => {
        const { domain } = this.props.auth;

        Modal.confirm({
            title: 'Are you sure?',
            content: `This action will ${archived ? 'un' : ''}archive this campaign`,
            okText: 'Confirm',
            onOk: () => toggleCampaignArchive(domain.id, campid, !archived).then(() => {
                this.fetch(domain.id);
            })
        });
    };

    render() {
        const { campaigns, loading, error, errorMsg, sort } = this.state;
        const { domain } = this.props.auth;
        const PageWrapper = (children) => (
            <div className="campaigns-container">
                <MainHeadingBar title="Archived Campaigns" helpText="Here you will find all the campaigns that you've archived." pageCTA={
                    campaigns.length
                        ? <span className="sort">
                            Sort By Date: <Dropdown overlay={sortMenu}>
                                {
                                    sort.camp_created === -1
                                        ? <Button size="default">Desc. Order <Icon type="down" /></Button>
                                        : <Button size="default">Asc. Order <Icon type="down" /></Button>
                                }
                            </Dropdown>
                        </span>
                        : ''
                } {...this.props} />

                {children}
            </div>
        );

        if (loading) {
            return PageWrapper(<Card style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </Card>);
        }

        if (!loading && error) {
            return PageWrapper(<div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>);
        }

        const sortMenu = (<Menu>
            <Menu.Item key={0}>
                <a href='#/' onClick={() => this.fetch(domain.id, { camp_created: 1 })}>
                    Asc. Order
                </a>
            </Menu.Item>
            <Menu.Item key={1}>
                <a href='#/' onClick={() => this.fetch(domain.id, { camp_created: -1 })}>
                    Desc. Order
                </a>
            </Menu.Item>
        </Menu>);

        return PageWrapper(
            <div className="campaigns">
                <Row>
                    <Col xs={24} className="column">
                        {
                            campaigns.length
                                ? campaigns.map((campaign, i) => <Col xs={24} className="column" key={campaign._id.$id}>
                                    <CampaignCard
                                        campaign={campaign}
                                        cardState={i === 0 ? 'open' : 'closed'}
                                        toggleCalloutStatus={(key, status, campid) => this.toggleCalloutStatus(key, status, campid)}
                                        toggleCampaignStatus={(campid, status) => this.toggleCampaignStatus(campid, status)}
                                        toggleCampaignArchive={(campid, archived) => this.toggleCampaignArchive(campid, archived)}
                                    />
                                </Col>)
                                : <NoData
                                    image={Placeholder}
                                    heading="No Campaigns Found!"
                                />
                        }
                    </Col>
                    <Pagination
                        onChange={(page) => this.goToPage(page)}
                        current={this.state.paging.current}
                        total={this.state.total}
                        pageSize={this.state.paging.pageSize} />
                </Row>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(ArchivedCampaigns);