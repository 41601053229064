import React, { Component } from 'react';
import './index.scss';
import { Divider, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';

import { ReactComponent as ArrowRight } from '../../../../assets/images/icons/arrowright.svg';
import { ReactComponent as MailerLite } from '../../../../assets/images/icons/integrations/mailerlite.svg';
import { ReactComponent as Aweber } from '../../../../assets/images/icons/integrations/aweber.svg';
import { ReactComponent as SendLane } from '../../../../assets/images/icons/integrations/sendlaneblack.svg';
import InfusionSoftImg from '../../../../assets/images/icons/integrations/infusionsoft.png';
import ActiveCampaignImg from '../../../../assets/images/icons/integrations/activecampaign.png';
import MauticImg from '../../../../assets/images/icons/integrations/mautic.png';
import CustomerlyImg from '../../../../assets/images/icons/integrations/customerly.svg';
import MailwizzImg from '../../../../assets/images/icons/integrations/mailwizz.png';
import LeadLoversImg from '../../../../assets/images/icons/integrations/leadlovers.svg';
import PerkzillaImg from '../../../../assets/images/icons/integrations/perkzilla.png';
import DripImg from '../../../../assets/images/icons/integrations/drip.svg';
import ConvertkitImg from '../../../../assets/images/icons/integrations/convertkit.png';
import KlaviyoImg from '../../../../assets/images/icons/integrations/klaviyo.png';
import SGAutoresponderImg from '../../../../assets/images/icons/integrations/sgautoresponder.png';
import IcontactImg from '../../../../assets/images/icons/integrations/i_contact.png';
import MailchimpImg from '../../../../assets/images/icons/integrations/mail_chimp.png';
import getresponseImg from '../../../../assets/images/icons/integrations/getresponse.png';

import { getIntegrationsByDomId } from '../../../../helpers/ajax/integrationsAjax';

import ActiveCampaignModal from './IntegrationModals/ActiveCampaignModal';
import GetResponseModal from './IntegrationModals/GetResponseModal';
import MailChimpModal from './IntegrationModals/MailChimpModal';
import IContactModal from './IntegrationModals/IContactModal';
import SendLaneModal from './IntegrationModals/SendlaneModal';
import MailerliteModal from './IntegrationModals/MailerliteModal';
import SGAutoresponderModal from './IntegrationModals/SGAutoresponderModal';
import KlaviyoModal from './IntegrationModals/KlaviyoModal';
import ConvertKitModal from './IntegrationModals/ConvertKitModal';
import DripModal from './IntegrationModals/DripModal';
import PerkZillaModal from './IntegrationModals/PerkZillaModal';
import LeadLoversModal from './IntegrationModals/LeadLoversModal';
import MailwizzModal from './IntegrationModals/MailwizzModal';
import CustomerlyModal from './IntegrationModals/CustomerlyModal';
import MauticModal from './IntegrationModals/MauticModal';

class Integrations extends Component {
    state = {
        error: false,
        errorMsg: '',
        loading: false,
        aweber: {},
        infusionsoft: {},
        sgautoresponder: {},
        mailwizz: {},
        active_campaign: {},
        customerly: {},
        getresponse: {},
        mailchimp: {},
        mailerlite: {},
        icontact: {},
        sendlane: {},
        klaviyo: {},
        leadlovers: {},
        convertkit: {},
        drip: {},
        perkzilla: {},
        active_campaignModalVisible: false,
        getresponseModalVisible: false,
        mailchimpModalVisible: false,
        icontactModalVisible: false,
        sendlaneModalVisible: false,
        mailerliteModalVisible: false,
        sgautoresponderModalVisible: false,
        klaviyoModalVisible: false,
        convertkitModalVisible: false,
        dripModalVisible: false,
        perkzillaModalVisible: false,
        leadloversModalVisible: false,
        mailwizzModalVisible: false,
        customerlyModalVisible: false,
        mautic: {},
        mauticModalVisible: false,
    };

    changeState = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        })
    };

    toggleState = (field) => {
        this.setState({ ...this.state, [field]: !this.state[field] });
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        getIntegrationsByDomId(this.props.auth.domain.id).then(res => {
            this.setState({
                ...this.state,
                ...res
            });
        });
    }

    render() {
        const {
            aweber,
            infusionsoft,
            sgautoresponder,
            mailwizz,
            active_campaign,
            mautic,
            customerly,
            getresponse,
            mailchimp,
            mailerlite,
            icontact,
            sendlane,
            klaviyo,
            leadlovers,
            convertkit,
            drip,
            perkzilla,
            active_campaignModalVisible,
            getresponseModalVisible,
            mailchimpModalVisible,
            icontactModalVisible,
            sendlaneModalVisible,
            mailerliteModalVisible,
            sgautoresponderModalVisible,
            klaviyoModalVisible,
            convertkitModalVisible,
            dripModalVisible,
            perkzillaModalVisible,
            leadloversModalVisible,
            mailwizzModalVisible,
            customerlyModalVisible,
            mauticModalVisible
        } = this.state;
        const { domain } = this.props.auth;

        const integrationStatusText = (status) => (status === 1 || status === "1")
            ? <a href="#/" onClick={() => false}>
                Edit Integration
            <ArrowRight />
            </a>
            : <a href="#/" onClick={() => false}>
                Begin Integration
            <ArrowRight />
            </a>;

        return (
            <div className="integrations">
                <h3 className="heading">Integrations <Tooltip title="Here you can manage your autoresponder integrations."><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></h3>

                <Divider />

                <p className="disclaimer">
                    Select an Email Autoresponder Service to integrate with or configure:
                </p>

                <div className="integration-boxes">
                    <div
                        className={`integration-box ${aweber.status === 1 && 'active'}`}
                        onClick={() => window.location = `/integrations/auth/${domain.id}/aweber`}
                    >
                        <Aweber /> {integrationStatusText(aweber.status)}
                    </div>

                    <div
                        className={`integration-box ${infusionsoft.status === 1 && 'active'}`}
                        onClick={() => window.location = `/integrations/auth/${domain.id}/infusionsoft`}
                    >
                        <img alt="integration-img" src={InfusionSoftImg} /> {integrationStatusText(infusionsoft.status)}
                    </div>

                    <div
                        className={`integration-box ${active_campaign.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('active_campaignModalVisible')}
                    >
                        <img alt="integration-img" src={ActiveCampaignImg} /> {integrationStatusText(active_campaign.status)}
                    </div>

                    <div
                        className={`integration-box ${getresponse.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('getresponseModalVisible')}
                    >
                        <img alt="integration-img" src={getresponseImg} /> {integrationStatusText(getresponse.status)}
                    </div>

                    <div
                        className={`integration-box ${mailchimp.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('mailchimpModalVisible')}
                    >
                        <img alt="integration-img" src={MailchimpImg} /> {integrationStatusText(mailchimp.status)}
                    </div>

                    <div
                        className={`integration-box ${icontact.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('icontactModalVisible')}
                    >
                        <img alt="integration-img" src={IcontactImg} /> {integrationStatusText(icontact.status)}
                    </div>

                    <div
                        className={`integration-box ${sendlane.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('sendlaneModalVisible')}
                    >
                        <SendLane /> {integrationStatusText(sendlane.status)}
                    </div>

                    <div
                        className={`integration-box ${mailerlite.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('mailerliteModalVisible')}
                    >
                        <MailerLite /> {integrationStatusText(mailerlite.status)}
                    </div>

                    <div
                        className={`integration-box ${sgautoresponder.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('sgautoresponderModalVisible')}
                    >
                        <img
                            alt="integration-img"
                            src={SGAutoresponderImg}
                            style={{ background: "#2f756d", padding: "5px" }}
                        /> {integrationStatusText(sgautoresponder.status)}
                    </div>

                    <div
                        className={`integration-box ${klaviyo.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('klaviyoModalVisible')}
                    >
                        <img
                            alt="integration-img"
                            src={KlaviyoImg}
                            style={{ background: "black", padding: "5px" }}
                        /> {integrationStatusText(klaviyo.status)}
                    </div>

                    <div
                        className={`integration-box ${convertkit.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('convertkitModalVisible')}
                    >
                        <img alt="integration-img" src={ConvertkitImg} /> {integrationStatusText(convertkit.status)}
                    </div>

                    <div
                        className={`integration-box ${drip.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('dripModalVisible')}
                    >
                        <img alt="integration-img" src={DripImg} /> {integrationStatusText(drip.status)}
                    </div>

                    <div
                        className={`integration-box ${perkzilla.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('perkzillaModalVisible')}
                    >
                        <img alt="integration-img" src={PerkzillaImg} /> {integrationStatusText(perkzilla.status)}
                    </div>

                    <div
                        className={`integration-box ${leadlovers.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('leadloversModalVisible')}
                    >
                        <img alt="integration-img" src={LeadLoversImg} /> {integrationStatusText(leadlovers.status)}
                    </div>

                    <div
                        className={`integration-box ${mailwizz.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('mailwizzModalVisible')}
                    >
                        <img alt="integration-img" src={MailwizzImg} /> {integrationStatusText(mailwizz.status)}
                    </div>

                    <div
                        className={`integration-box ${customerly.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('customerlyModalVisible')}
                    >
                        <img
                            alt="integration-img"
                            src={CustomerlyImg}
                            style={{ background: "#38aaff", padding: "5px" }}
                        /> {integrationStatusText(customerly.status)}
                    </div>

                    <div
                        className={`integration-box ${mautic.status === 1 && 'active'}`}
                        onClick={() => this.toggleState('mauticModalVisible')}
                    >
                        <img alt="integration-img" src={MauticImg} /> {integrationStatusText(mautic.status)}
                    </div>

                    <div className={`integration-box`} style={{ visibility: 'hidden' }}>
                        Mautic {integrationStatusText(mautic.status)}
                    </div>
                </div >

                <div className="integration-modals">
                    <ActiveCampaignModal
                        visible={active_campaignModalVisible}
                        closeModal={() => {
                            this.toggleState('active_campaignModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(active_campaign).length ? 'edit_modal' : 'add_modal'}
                        settings={active_campaign}
                        domid={domain.id}
                    />

                    <GetResponseModal
                        visible={getresponseModalVisible}
                        closeModal={() => {
                            this.toggleState('getresponseModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(getresponse).length ? 'edit_modal' : 'add_modal'}
                        settings={getresponse}
                        domid={domain.id}
                    />

                    <MailChimpModal
                        visible={mailchimpModalVisible}
                        closeModal={() => {
                            this.toggleState('mailchimpModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(mailchimp).length ? 'edit_modal' : 'add_modal'}
                        settings={mailchimp}
                        domid={domain.id}
                    />

                    <IContactModal
                        visible={icontactModalVisible}
                        closeModal={() => {
                            this.toggleState('icontactModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(icontact).length ? 'edit_modal' : 'add_modal'}
                        settings={icontact}
                        domid={domain.id}
                    />

                    <SendLaneModal
                        visible={sendlaneModalVisible}
                        closeModal={() => {
                            this.toggleState('sendlaneModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(sendlane).length ? 'edit_modal' : 'add_modal'}
                        settings={sendlane}
                        domid={domain.id}
                    />

                    <MailerliteModal
                        visible={mailerliteModalVisible}
                        closeModal={() => {
                            this.toggleState('mailerliteModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(mailerlite).length ? 'edit_modal' : 'add_modal'}
                        settings={mailerlite}
                        domid={domain.id}
                    />

                    <SGAutoresponderModal
                        visible={sgautoresponderModalVisible}
                        closeModal={() => {
                            this.toggleState('sgautoresponderModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(sgautoresponder).length ? 'edit_modal' : 'add_modal'}
                        settings={sgautoresponder}
                        domid={domain.id}
                    />

                    <KlaviyoModal
                        visible={klaviyoModalVisible}
                        closeModal={() => {
                            this.toggleState('klaviyoModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(klaviyo).length ? 'edit_modal' : 'add_modal'}
                        settings={klaviyo}
                        domid={domain.id}
                    />

                    <ConvertKitModal
                        visible={convertkitModalVisible}
                        closeModal={() => {
                            this.toggleState('convertkitModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(convertkit).length ? 'edit_modal' : 'add_modal'}
                        settings={convertkit}
                        domid={domain.id}
                    />

                    <DripModal
                        visible={dripModalVisible}
                        closeModal={() => {
                            this.toggleState('dripModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(drip).length ? 'edit_modal' : 'add_modal'}
                        settings={drip}
                        domid={domain.id}
                    />

                    <PerkZillaModal
                        visible={perkzillaModalVisible}
                        closeModal={() => {
                            this.toggleState('perkzillaModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(perkzilla).length ? 'edit_modal' : 'add_modal'}
                        settings={perkzilla}
                        domid={domain.id}
                    />

                    <LeadLoversModal
                        visible={leadloversModalVisible}
                        closeModal={() => {
                            this.toggleState('leadloversModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(leadlovers).length ? 'edit_modal' : 'add_modal'}
                        settings={leadlovers}
                        domid={domain.id}
                    />

                    <MailwizzModal
                        visible={mailwizzModalVisible}
                        closeModal={() => {
                            this.toggleState('mailwizzModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(mailwizz).length ? 'edit_modal' : 'add_modal'}
                        settings={mailwizz}
                        domid={domain.id}
                    />

                    <CustomerlyModal
                        visible={customerlyModalVisible}
                        closeModal={() => {
                            this.toggleState('customerlyModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(customerly).length ? 'edit_modal' : 'add_modal'}
                        settings={customerly}
                        domid={domain.id}
                    />

                    <MauticModal
                        visible={mauticModalVisible}
                        closeModal={() => {
                            this.toggleState('mauticModalVisible');
                            this.fetch();
                        }}
                        modalType={Object.keys(mautic).length ? 'edit_modal' : 'add_modal'}
                        settings={mautic}
                        domid={domain.id}
                    />
                </div>
            </div >
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(Integrations);