import React, { Component } from 'react';
import { Button, Icon, Input, Switch, Select, Row, Col, message, Tooltip } from 'antd';
import AutoRecordIcon from '../../../../assets/images/record_sources/auto_record.svg'
import CustomCurationIcon from '../../../../assets/images/record_sources/custom_curation.svg'
import WebhookIcon from '../../../../assets/images/record_sources/webhook.svg'
import ClickFunnelsIcon from '../../../../assets/images/record_sources/clickfunnels.svg'
import InfusionsoftIcon from '../../../../assets/images/record_sources/infusionsoft.svg'
import ManualRecords from './ManualRecords';
import { get, isEqual } from 'lodash';
import CSVReader from 'react-csv-reader';
import moment from 'moment';
import './index.scss';

const { Option } = Select;

class CreateCampaignTab extends Component {
  componentWillReceiveProps(props) {
    if (!isEqual(props.campaign, this.props.campaign)) {
      this.setState();
    }
  }

  addURL(type) {
    this.props.onUpdate(
      type,
      [...get(this.props.campaign, type, []),
      {
        "id": Date.now(),
        "type": "CONTAINS",
        "data": "",
      }
      ],
    )
  }

  deleteURL(type, index) {
    const val = [...get(this.props.campaign, type, []).slice(0, index), ...get(this.props.campaign, type, []).slice(index + 1)];
    this.props.onUpdate(
      type,
      val.length ? val : [{ "id": Date.now(), "type": "CONTAINS", "data": "" }]
    )
  }

  loadDataFromCSV(data) {
    const ignoreFirstLine = parseInt(get(this.props.campaign, "camp_recentactivity.settings.ignorecsvfirstrow", 0)) === 0 ? false : true;
    if (ignoreFirstLine) {
      data = data.slice(1);
    }
    this.props.onUpdate("camp_manualrecords", data.filter(r => {
      return r[0].trim() !== '';
    }).map((r) => ({
      name: r[0] ? r[0] : '',
      location: r[1] ? r[1] : '',
      email: r[2] ? r[2] : '',
      date: moment(r[3]).isValid() ? moment(r[3]).format("YYYY-MM-DD HH:mm:ss") : '',
    })));
  }

  render() {
    return (
      <div className="campaign-tab create-campaign-tab">
        <div className="tab-header">
          <h3 className="purple">Create Campaign</h3>
          <Button size="large" className="btn-green right" onClick={() => this.props.nextTab()}>Continue <Icon type="arrow-right" /></Button>
        </div>
        <div className="tab-body">
          <div className={`ant-form-item-control ${this.props.errors.camp_name ? 'has-error' : ''}`}>
            <p className="form-labels"><span>Campaign Name</span></p>
            <Input
              placeholder="Give your campaign a name"
              style={{ width: "320px", marginLeft: "12px" }}
              defaultValue={get(this.props.campaign, "camp_name", "")}
              onChange={(e) => { this.props.onUpdate("camp_name", e.target.value) }} />
            {this.props.errors.camp_name && <div className="ant-form-explain">{this.props.errors.camp_name}</div>}
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels"><span style={{ width: 332 }}>Enable Right To Left Language Support <Tooltip title="Enable to make sure that text flows from Right to Left (RTL) instead of Left to Right (LTR). Enabling recommended for Hebrew, Arabic, etc."><Icon type="info-circle" /></Tooltip></span>
              <Switch
                size="small"
                className="switch-green"
                defaultChecked={parseInt(get(this.props.campaign, "camp_settings.rtl", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_settings.rtl", checked ? 1 : 0) }} />
            </p>
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels"><span style={{ width: 332 }}>Enable Callout Translation</span>
              <Switch
                size="small"
                className="switch-green"
                defaultChecked={parseInt(get(this.props.campaign, "camp_translation.enabled", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_translation.enabled", checked ? 1 : 0) }} />
            </p>
          </div>

          {parseInt(get(this.props.campaign, "camp_translation.enabled", 0)) === 1 &&
            <div className="ant-form-item-control">
              <p className="form-labels">Choose Language</p>
              <Select
                defaultValue={get(this.props.campaign, "camp_translation.language", "english")}
                onChange={val => this.props.onUpdate(`camp_translation[language]`, val)}
                style={{ width: 300 }}>
                <Option value="english" key="english">English</Option>
                <Option value="german" key="german">German</Option>
                <Option value="spanish" key="spanish">Spanish</Option>
                <Option value="portuguese" key="portuguese">Portuguese</Option>
                <Option value="chinese" key="chinese">Chinese</Option>
                <Option value="french" key="french">French</Option>
                <Option value="italian" key="italian">Italian</Option>
                {this.props.translations.map((t) => <Option value={t._id["$id"]} key={t._id["$id"]}>[Custom] {t.translation_name}</Option>)}
              </Select>
            </div>
          }

          <div className="ant-form-item-control" style={{ marginBottom: "0 !important" }}>
            <p className="form-labels"><span>Display Settings</span></p>
          </div>

          <div className="display-settings" style={{ paddingLeft: 36, marginTop: "-16px" }}>
            <div className={`ant-form-item-control ${this.props.errors.camp_displayurl ? 'has-error' : ''}`}>
              <p className="form-labels" style={{ fontWeight: 400 }}>Display Alerts On Following URL(s)</p>
            </div>
            {get(this.props.campaign, "camp_displayurl").map((url, index) => (
              <div className={`ant-form-item-control url-field ${(this.props.errors.camp_displayurl && url.data.length === 0) ? 'has-error' : ''}`} key={url.id}>
                <Input
                  defaultValue={url.data}
                  onChange={e => { this.props.onUpdate(`camp_displayurl[${index}][data]`, e.target.value) }}
                  addonBefore={
                    <Select
                      defaultValue={url.type}
                      onChange={val => this.props.onUpdate(`camp_displayurl[${index}][type]`, val)}
                      style={{ width: 139 }}>
                      <Option value="EXACT">Exactly Matches</Option>
                      <Option value="CONTAINS" selected="">Contains</Option>
                      <Option value="REGEX">Regex</Option>
                    </Select>}
                  style={{ width: 487, verticalAlign: "middle" }} />
                <Button
                  className="btn-red"
                  size="large"
                  onClick={() => this.addURL("camp_displayurl")}
                  style={{ marginLeft: 5 }}><Icon type="plus" /> Add More</Button>
                <Icon
                  type="plus"
                  style={{ color: "rgba(0,0,0,0.65)", marginLeft: 11, fontSize: 24, transform: "translate(0px,3px) rotate(45deg)", cursor: "pointer" }}
                  onClick={() => this.deleteURL("camp_displayurl", index)} />
              </div>
            ))}
            {this.props.errors.camp_displayurl && <div className="ant-form-explain">{this.props.errors.camp_displayurl}</div>}

            <div className="ant-form-item-control">
              <p className="form-labels" style={{ fontWeight: 400 }}>Don’t Display Alerts On Following URL(s)
              <Switch
                  size="small"
                  className="switch-green"
                  defaultChecked={get(this.props.campaign, "camp_excludeurl", []).length ? true : false}
                  onChange={(checked) => this.props.onUpdate(`camp_excludeurl`, checked ? [{ "id": Date.now(), "type": "CONTAINS", "data": "" }] : [])} />
              </p>
            </div>
            {get(this.props.campaign, "camp_excludeurl", []).map((url, index) => (
              <div className={`ant-form-item-control url-field ${(this.props.errors.camp_excludeurl && url.data.length === 0) ? 'has-error' : ''}`} key={url.id}>
                <Input
                  defaultValue={url.data}
                  onChange={e => { this.props.onUpdate(`camp_excludeurl[${index}][data]`, e.target.value) }}
                  addonBefore={
                    <Select
                      defaultValue={url.type}
                      onChange={val => this.props.onUpdate(`camp_excludeurl[${index}][type]`, val)}
                      style={{ width: 139 }}>
                      <Option value="EXACT">Exactly Matches</Option>
                      <Option value="CONTAINS" selected="">Contains</Option>
                      <Option value="REGEX">Regex</Option>
                    </Select>}
                  style={{ width: 487, verticalAlign: "middle" }} />
                <Button
                  className="btn-red"
                  size="large"
                  onClick={() => this.addURL("camp_excludeurl")}
                  style={{ marginLeft: 5 }}><Icon type="plus" /> Add More</Button>
                <Icon
                  type="plus"
                  style={{ color: "rgba(0,0,0,0.65)", marginLeft: 11, fontSize: 24, transform: "translate(0px,3px) rotate(45deg)", cursor: "pointer" }}
                  onClick={() => this.deleteURL("camp_excludeurl", index)} />
              </div>
            ))}
            {this.props.errors.camp_excludeurl && <div className="ant-form-explain">{this.props.errors.camp_excludeurl}</div>}

            <div className="ant-form-item-control">
              <p className="form-labels" style={{ fontWeight: 400 }}>Display Alerts With Following Referer URL(s)
              <Switch
                  size="small"
                  className="switch-green"
                  defaultChecked={get(this.props.campaign, "camp_referrerurl", []).length ? true : false}
                  onChange={(checked) => this.props.onUpdate(`camp_referrerurl`, checked ? [{ "id": Date.now(), "type": "CONTAINS", "data": "" }] : [])} />
              </p>
            </div>
            {get(this.props.campaign, "camp_referrerurl", []).map((url, index) => (
              <div className={`ant-form-item-control url-field ${(this.props.errors.camp_referrerurl && url.data.length === 0) ? 'has-error' : ''}`} key={url.id}>
                <Input
                  defaultValue={url.data}
                  onChange={e => { this.props.onUpdate(`camp_referrerurl[${index}][data]`, e.target.value) }}
                  addonBefore={
                    <Select
                      defaultValue={url.type}
                      onChange={val => this.props.onUpdate(`camp_referrerurl[${index}][type]`, val)}
                      style={{ width: 139 }}>
                      <Option value="EXACT">Exactly Matches</Option>
                      <Option value="CONTAINS" selected="">Contains</Option>
                      <Option value="REGEX">Regex</Option>
                    </Select>}
                  style={{ width: 487, verticalAlign: "middle" }} />
                <Button
                  className="btn-red"
                  size="large"
                  onClick={() => this.addURL("camp_referrerurl")}
                  style={{ marginLeft: 5 }}><Icon type="plus" /> Add More</Button>
                <Icon
                  type="plus"
                  style={{ color: "rgba(0,0,0,0.65)", marginLeft: 11, fontSize: 24, transform: "translate(0px,3px) rotate(45deg)", cursor: "pointer" }}
                  onClick={() => this.deleteURL("camp_referrerurl", index)} />
              </div>
            ))}
            {this.props.errors.camp_referrerurl && <div className="ant-form-explain">{this.props.errors.camp_referrerurl}</div>}
          </div>

          <div className="ant-form-item-control">
            <p className="form-labels"><span>Capture Settings</span></p>
            <span style={{ background: "rgb(253, 209, 132)", margin: "20px 0px 0px 32px", fontSize: "14px", lineHeight: "22px", padding: "10px 15px" }}><Icon type="exclamation-circle" /> Not required for User Reviews & Announcements.</span>
          </div>

          <div className="record-sources" style={{ paddingLeft: 36, marginTop: "-16px" }}>
            <div className="ant-form-item-control">
              <p className="form-labels" style={{ fontWeight: 400 }}>Record Sources</p>
            </div>

            <Row gutter={48} style={{ display: "flex", marginBottom: 20 }}>
              <Col xs={8} style={{ flex: 1 }}>
                <div
                  className={`record-source ${get(this.props.campaign, "camp_source", "AUTO_RECORD") === "AUTO_RECORD" ? 'selected' : ''}`}
                  onClick={() => this.props.onUpdate("camp_source", "AUTO_RECORD")}>
                  <div className="record-source-content">
                    <p className="record-source-title">Auto Record</p>
                    <p className="record-source-desc">Your leads will automatically be captured from web pages</p>
                    <p className="record-source-img"><img alt="" src={AutoRecordIcon} /></p>
                  </div>
                </div>
              </Col>
              <Col xs={8} style={{ flex: 1 }}>
                <div
                  className={`record-source ${get(this.props.campaign, "camp_source", "AUTO_RECORD") === "CUSTOM_CURATION" ? 'selected' : ''}`}
                  onClick={() => this.props.onUpdate("camp_source", "CUSTOM_CURATION")}>
                  <div className="record-source-content">
                    <p className="record-source-title">Custom Curation</p>
                    <p className="record-source-desc">Upload a CSV or choose from Pre-Listed files to show Callouts on your pages </p>
                    <p className="record-source-img"><img alt="" src={CustomCurationIcon} /></p>
                  </div>
                </div>
              </Col>
              <Col xs={8} style={{ flex: 1 }}>
                <div
                  className={`record-source ${get(this.props.campaign, "camp_source", "AUTO_RECORD") === "CUSTOM_WEBHOOK" ? 'selected' : ''}`}
                  onClick={() => this.props.onUpdate("camp_source", "CUSTOM_WEBHOOK")}>
                  <div className="record-source-content">
                    <p className="record-source-title">Webhook</p>
                    <p className="record-source-desc">Send LetConvert a custom HTTP post payload</p>
                    <p className="record-source-img"><img alt="" src={WebhookIcon} /></p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={48} style={{ display: "flex", marginBottom: 20 }}>
              <Col xs={8} style={{ flex: 1 }}>
                <div
                  className={`record-source ${get(this.props.campaign, "camp_source", "AUTO_RECORD") === "CLICKFUNNELS" ? 'selected' : ''}`}
                  onClick={() => this.props.onUpdate("camp_source", "CLICKFUNNELS")}>
                  <div className="record-source-content">
                    <p className="record-source-title">ClickFunnels</p>
                    <p className="record-source-desc">Capture leads with webhooks. Perfect for pages built on clickfunnels.</p>
                    <p className="record-source-img"><img alt="" src={ClickFunnelsIcon} /></p>
                  </div>
                </div>
              </Col>
              <Col xs={8} style={{ flex: 1 }}>
                <div
                  className={`record-source ${get(this.props.campaign, "camp_source", "AUTO_RECORD") === "INFUSIONSOFT" ? 'selected' : ''}`}
                  onClick={() => this.props.onUpdate("camp_source", "INFUSIONSOFT")}>
                  <div className="record-source-content">
                    <p className="record-source-title">Infusionsoft</p>
                    <p className="record-source-desc">Capture leads & buyers with webhooks. Integrate directly into a campaign sequence.</p>
                    <p className="record-source-img"><img alt="" src={InfusionsoftIcon} /></p>
                  </div>
                </div>
              </Col>
              <Col xs={8} style={{ flex: 1 }}>
              </Col>
            </Row>

            {get(this.props.campaign, "camp_source", "AUTO_RECORD") === "AUTO_RECORD" &&
              <div className="capture-settings">
                <div className="ant-form-item-control">
                  <p className="form-labels" style={{ fontWeight: 400 }}>Capture Events On Separate URL <Tooltip title={<span>Make sure that:<br />There is an email field present on your page.<br />There is some activity on the email field.</span>}><Icon type="info-circle" /></Tooltip>
                    <Switch
                      size="small"
                      className="switch-green"
                      defaultChecked={get(this.props.campaign, "camp_captureurl", []).length ? true : false}
                      onChange={(checked) => this.props.onUpdate(`camp_captureurl`, checked ? [{ "id": Date.now(), "type": "CONTAINS", "data": "" }] : [])} />
                  </p>
                </div>
                {get(this.props.campaign, "camp_captureurl", []).map((url, index) => (
                  <div className={`ant-form-item-control url-field ${(this.props.errors.camp_captureurl && url.data.length === 0) ? 'has-error' : ''}`} key={url.id}>
                    <Input
                      defaultValue={url.data}
                      onChange={e => { this.props.onUpdate(`camp_captureurl[${index}][data]`, e.target.value) }}
                      addonBefore={
                        <Select style={{ width: 139 }}
                          defaultValue={url.type}
                          onChange={val => this.props.onUpdate(`camp_captureurl[${index}][type]`, val)}>
                          <Option value="EXACT">Exactly Matches</Option>
                          <Option value="CONTAINS" selected="">Contains</Option>
                          <Option value="REGEX">Regex</Option>
                        </Select>}
                      style={{ width: 487, verticalAlign: "middle" }} />
                    <Button className="btn-red" size="large" style={{ marginLeft: 5 }} onClick={() => this.addURL('camp_captureurl')}><Icon type="plus" /> Add More</Button>
                    <Icon type="plus" style={{ color: "rgba(0,0,0,0.65)", marginLeft: 11, fontSize: 24, transform: "translate(0px,3px) rotate(45deg)", cursor: "pointer" }} onClick={() => this.deleteURL("camp_captureurl", index)} />
                  </div>
                ))}
                {this.props.errors.camp_captureurl && <div className="ant-form-explain">{this.props.errors.camp_captureurl}</div>}
              </div>
            }

            {get(this.props.campaign, "camp_source", "AUTO_RECORD") === "CUSTOM_CURATION" &&
              <React.Fragment>
                <div className="ant-form-item-control">
                  <p className="form-labels">Upload Records from CSV (<a href="/downloads/csv/sample.csv" target="_blank">Download Sample CSV</a>)</p>
                  <CSVReader
                    cssClass="csv-upload ant-upload ant-upload-select ant-upload-select-picture-card"
                    label={<span className="csv-upload-label"><Icon type="plus" /><br />Load CSV File</span>}
                    onFileLoaded={(data) => this.loadDataFromCSV(data)}
                    onError={() => message.error("There was an error reading the CSV file")}
                  />
                </div>

                <div className="ant-form-item-control">
                  <p className="form-labels">Ignore First Row Of CSV
                  <Switch
                      size="small"
                      className="switch-green"
                      defaultChecked={parseInt(get(this.props.campaign, "camp_recentactivity.settings.ignorecsvfirstrow", 0)) === 0 ? false : true}
                      onChange={checked => this.props.onUpdate("camp_recentactivity.settings.ignorecsvfirstrow", checked ? 1 : 0)} />
                  </p>
                </div>

                <div className="ant-form-item-control">
                  <ManualRecords
                    records={get(this.props.campaign, "camp_manualrecords", [])}
                    onUpdate={(records) => this.props.onUpdate("camp_manualrecords", records)} />
                </div>
              </React.Fragment>
            }
          </div>
          <div className="ant-form-item-control">
            <p className="form-labels"><span>Use Absolute URLs</span>
              <Switch
                size="small"
                className="switch-green"
                checked={parseInt(get(this.props.campaign, "camp_settings.absoluteurl", 0)) === 0 ? false : true}
                onChange={(checked) => { this.props.onUpdate("camp_settings.absoluteurl", checked ? 1 : 0) }} />
            </p>
            <span style={{ background: "rgb(253, 209, 132)", margin: "20px 0px 0px 32px", fontSize: "14px", lineHeight: "22px", padding: "10px 15px" }}><Icon type="exclamation-circle" /> Keep it disabled to ignore http(s) & the query parameters such as xyz, abc etc.</span>
          </div>
          <Button
            size="large"
            className="btn-green"
            style={{ marginTop: 20 }}
            onClick={() => this.props.nextTab()}>Continue <Icon type="arrow-right" /></Button>
          {!this.props.edit && <Button className="btn-red" size="large" style={{ width: 169, margin: "0 0 0 15px", "fontSize": 16, "height": 40, maxHeight: "unset" }} onClick={() => this.props.draft()} loading={this.props.saving}>Save Draft</Button>}
        </div>
      </div>
    )
  }
}

export default CreateCampaignTab;