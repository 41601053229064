import React from "react";
import "./index.scss";
import { Button, Input, Icon } from "antd";
import { ReactComponent as Wordpress } from "../../../../assets/images/icons/wordpress.svg";
import { BASE_URL } from "../../../../config";
import ClickToCopy from "../../Partials/ClickToCopy";

const PixelInstallOptions = props => {
  const { id, dom_hash } = props;

  return (
    <div className="pixel-install-options">
      <p className="disclaimer">
        Add LetConvert snippet to your website footer. Add it to every page of
        your website where you want to show LetConvert Callouts:
      </p>

      <Input.TextArea
        autosize={{ minRows: 2 }}
        disabled={true}
        value={`<script src='//cdn.letconvert.com/js/main/${dom_hash}.js' async></script>`}
      />

      <ClickToCopy
        defaultText="Copy Code"
        textToCopy={`<script src='//cdn.letconvert.com/js/main/${dom_hash}.js' async></script>`}
      >
        <Button className="click-to-copy-btn">Copy Code</Button>
      </ClickToCopy>

      <p className="disclaimer or">OR</p>

      <p className="disclaimer wordpress-text">
        Download our WordPress plugin, install it on your website to activate
        it:
      </p>

      <Button
        href={`${BASE_URL}downloads/getwpplugin/${id}`}
        target="_blank"
        rel="no-opener no-referrer"
        type="default"
        className="pixel-btn wordpess-button"
      >
        <Icon component={Wordpress} />
        Download Plugin
      </Button>
    </div>
  );
};

export default PixelInstallOptions;
