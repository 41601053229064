import React, { Component } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Row, Col, Alert, Skeleton, Card, Button } from "antd";
import logo from "../../assets/images/logos/logo_dark.svg";
import PixelActions from "./PixelActions";
import OnboardingFooter from "../Dashboard/Partials/OnboardingFooter";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/images/icons/arrowright.svg";
import { ReactComponent as Stars } from "../../assets/images/icons/5stars.svg";
import { getDomainNextSteps } from "../../helpers/ajax/snapshotAjax";
import PixelInstallOptions from "../Dashboard/Partials/PixelInstallOptions";
import PixelVerificationAction from "../Dashboard/Partials/PixelVerificationAction";
import UserProfileForm from "./UserProfileForm";

class Steps extends Component {
  constructor(props) {
    super(props);
    document.title = props.title;
  }

  state = {
    nextsteps: {
      PIXEL: 0,
      PROFILE: 0,
      CAMPAIGN: 0,
    },
    loading: false,
    error: false,
    errorMsg: "",
    displayPixelActions: false,
    displayPixelInstallOptions: false,
    displayVerifyPixelAction: false,
    visibleModal: "",
    displayProfileForm: false,
  };

  componentDidMount = () => {
    if (this.props.auth.logged_in) {
      getDomainNextSteps(this.props.auth.domain.id).then((res) => {
        const { PIXEL, PROFILE } = res.nextsteps;
        // const { pathname } = this.props.location;

        this.setState(
          {
            ...this.state,
            ...res,
            displayPixelActions: PIXEL === 0 || PIXEL === "0",
            displayProfileForm:
              (PROFILE === 0 || PROFILE === "0") &&
              !(PIXEL === 0 || PIXEL === "0"),
          },
          () => {
            // if (("" + pathname[pathname.length - 1] === "3")) {
            //     this.show('displayPixelActions');
            // } else if (("" + pathname[pathname.length - 1] === "4")) {
            //     this.show('displayProfileForm');
            // }
          }
        );
      });
    }
  };

  changeState = (newState) => {
    this.setState({
      ...this.state,
      ...newState,
    });
  };

  show = (field) => {
    this.setState(
      {
        ...this.state,
        displayPixelActions: false,
        displayPixelInstallOptions: false,
        displayVerifyPixelAction: false,
        displayProfileForm: false,
      },
      () => {
        if (
          field === "displayProfileForm" &&
          this.state.nextsteps.PROFILE === "1"
        ) {
          if (this.state.nextsteps.CAMPAIGN === "1") {
            this.props.history.push(`/${this.props.auth.domain.id}`);
          } else {
            this.props.history.push(`/${this.props.auth.domain.id}`);
          }
        } else if (this.state.nextsteps.PIXEL === "1") {
          this.show("displayProfileForm");
        } else {
          this.setState({
            ...this.state,
            [field]: true,
          });
        }
      }
    );
  };

  toggleModal = (name) => {
    this.setState({
      ...this.state,
      visibleModal: name,
    });
  };

  render() {
    const {
      nextsteps,
      displayPixelActions,
      loading,
      error,
      errorMsg,
      displayPixelInstallOptions,
      displayVerifyPixelAction,
      visibleModal,
      displayProfileForm,
    } = this.state;
    const { PIXEL, PROFILE, CAMPAIGN } = nextsteps;
    const { domain, logged_in } = this.props.auth;

    if (!logged_in) return <Redirect to="/switch-account" />;
    if (Number(PIXEL) + Number(PROFILE) === 2) return <Redirect to="/" />;

    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Card>
            <Skeleton active />
          </Card>
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return (
      <Row className="steps">
        <Col xs={24} md={17} className="left">
          <img src={logo} className="logo" alt="logo" />
          <h2 className="title">
            {displayProfileForm
              ? "Let’s complete your account profile"
              : "Add LetConvert pixel to your website"}
          </h2>

          {displayPixelActions && (
            <div>
              <h5 className="sub-title">
                Follow the instuctions below or ask a teammate for help!
              </h5>
              <PixelActions
                show={(field) => this.show(field)}
                visibleModal={visibleModal}
                toggleModal={(field) => this.toggleModal(field)}
                domain={domain}
              />
            </div>
          )}

          {displayPixelInstallOptions && (
            <div className="pixel-install-options-container">
              <h5 className="sub-title">
                Follow the instuctions below or <u>ask a teammate for help!</u>
              </h5>
              <Card>
                <PixelInstallOptions {...domain} />
              </Card>

              <Button
                className="continue-btn"
                onClick={() => this.show("displayVerifyPixelAction")}
              >
                Continue <Arrow />
              </Button>
            </div>
          )}

          {displayVerifyPixelAction && (
            <div className="pixel-verify-action-container">
              <h5 className="sub-title">
                Let’s verify that the LetConvert pixel was installed
                successfully on your website.
              </h5>
              <Card>
                <PixelVerificationAction
                  {...domain}
                  onSkip={() => this.show("displayProfileForm")}
                />
              </Card>
            </div>
          )}

          {displayProfileForm && (
            <div className="user-profile-form-container">
              <h5 className="sub-title">
                Help us serve you better by telling us a bit about yourself
              </h5>
              <Card>
                <UserProfileForm {...this.props} />
              </Card>
            </div>
          )}

          <OnboardingFooter />
        </Col>

        <Col xs={0} md={7} className="right">
          <Link className="back-to-dashboard" to="/">
            Back to dashboard <Arrow />
          </Link>

          <ul>
            {PIXEL === "1" || PIXEL === 1 ? (
              <li className="done">Add LetConvert Pixel</li>
            ) : (
              <li
                className={!displayProfileForm ? "active" : ""}
                onClick={() => this.show("displayPixelActions")}
              >
                Add LetConvert Pixel
              </li>
            )}
            {PROFILE === "1" || PROFILE === 1 ? (
              <li className="done">Complete Your Profile</li>
            ) : (
              <li
                className={displayProfileForm ? "active" : ""}
                onClick={() => this.show("displayProfileForm")}
              >
                Complete Your Profile
              </li>
            )}
            {CAMPAIGN === "1" || CAMPAIGN === 1 ? (
              <li className="done">Launch A Campaign</li>
            ) : (
              <li
                className={PIXEL === "1" && PROFILE === "1" ? "active" : ""}
                onClick={() =>
                  this.props.history.push(`/${this.props.auth.domain.id}`)
                }
              >
                Launch A Campaign
              </li>
            )}
          </ul>

          <div className="bottom">
            <p className="text">
              “Trusted by over 9k marketers worldwide, LetConvert is built by
              LetX with a goal to help digital marketers convert & sell better
              and faster than ever”
            </p>

            <Stars />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(Steps);
