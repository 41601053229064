import React, { Component } from "react";
import "./index.scss";
import {
  Card,
  Table,
  Button,
  Icon,
  Tooltip,
  Row,
  Col,
  Modal,
  message
} from "antd";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { ReactComponent as EditReviewIcon } from "../../../assets/images/icons/editReview.svg";
import { ReactComponent as DeleteReviewIcon } from "../../../assets/images/icons/delete.svg";
import { CDN_URL } from "../../../config";
import {
  getAnnouncement,
  deleteAnnouncement
} from "../../../helpers/ajax/announcementAjax";
import LibraryModal from "../Library/LibraryModal";
import SearchField from "../Partials/SearchField";
import AddAnnouncements from "./AddAnnouncementModal";
import moment from "moment";

class Announcements extends Component {
  state = {
    selectedRowKeys: [],
    announcements: [],
    loading: false,
    error: false,
    errorMsg: "",
    filters: {
      search: ""
    },
    paging: {
      pageSize: 10,
      total: 0,
      page: 1
    },
    addAnnouncementModal: {
      visible: false,
      edit: false,
      announcement: {}
    },
    libraryModalVisible: false,
    libraryModalTab: "library"
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  fetchAnnouncements() {
    getAnnouncement(
      this.props.match.params.domid,
      this.state.paging.pageSize,
      (this.state.paging.page - 1) * this.state.paging.pageSize,
      this.state.filters.search
    ).then(res => this.setState(res));
  }

  componentDidMount() {
    this.fetchAnnouncements();
  }

  setFilter(key, value) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [key]: value
        },
        loading: true,
        paging: {
          pageSize: 10,
          total: 0,
          page: 1
        }
      },
      () => this.fetchAnnouncements()
    );
  }

  handleTableChange = pagination => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState(
      {
        announcements: [],
        loading: true,
        paging: {
          ...this.state.pagination,
          page: pager.current
        }
      },
      () => this.fetchAnnouncements()
    );
  };

  deleteSelectedAnnouncement(ids) {
    deleteAnnouncement(this.props.match.params.domid, ids)
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            announcements: this.state.announcements.filter(
              r => ids.indexOf(r._id["$id"]) === -1
            )
          });
          message.success("Announcement(s) deleted successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(e => {
        message.error(e.message);
      });
  }

  confirmDelete(ids) {
    const that = this;
    Modal.confirm({
      title: "Are you sure",
      content: "Are you sure you want to delete the announcement(s).",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        that.deleteSelectedAnnouncement(ids);
      }
    });
  }

  toggleAddAnnouncementModal() {
    this.setState(
      {
        addAnnouncementModal: {
          ...this.state.addAnnouncementModal,
          visible: !this.state.addAnnouncementModal.visible
        }
      },
      () => {
        if (!this.state.addAnnouncementModal.visible) {
          this.setState({
            addAnnouncementModal: {
              ...this.state.addAnnouncementModal,
              edit: undefined,
              announcement: {}
            }
          });
        }
      }
    );
  }

  toggleEditAnnouncementModal(announcement) {
    console.log(announcement);
    this.setState({
      addAnnouncementModal: {
        ...this.state.addAnnouncementModal,
        visible: !this.state.addAnnouncementModal.visible,
        edit: true,
        announcement
      }
    });
  }

  onAddAnnouncement() {
    this.setState(
      {
        selectedRowKeys: [],
        loading: true,
        paging: {
          pageSize: 10,
          total: 0,
          page: 1
        },
        addAnnouncementModal: {
          visible: false,
          edit: false,
          announcement: {}
        }
      },
      () => this.fetchAnnouncements()
    );
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const columns = [
      {
        title: "Announcement",
        dataIndex: null,
        width: "300px",
        render: (_, record) => {
          return (
            <div className="announcement-preview">
              <div
                className="announcement-previewimage"
                style={{
                  background: `url(${CDN_URL}/announcements/${record.announcement_image})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}
              ></div>
              <div className="announcement-content">
                <p className="announcement_title">
                  {record.announcement_title}
                </p>
                <p className="announcement_message" style={{ width: 300 }}>
                  <Tooltip
                    placement="bottomLeft"
                    title={record.announcement_message}
                  >
                    {record.announcement_message}
                  </Tooltip>
                </p>
              </div>
            </div>
          );
        }
      },
      {
        title: "Date",
        dataIndex: "announcement_added",
        width: "200px",
        render: date => moment(date).format("MMM DD, YYYY HH:MM")
      },
      {
        title: "Actions",
        dataIndex: "id",
        width: "200px",
        render: (_, record) => (
          <div className="actions-div">
            <Tooltip placement="top" title="Edit">
              <Button
                type="default"
                className="edit-btn"
                onClick={() => this.toggleEditAnnouncementModal(record)}
              >
                <Icon component={EditReviewIcon} />
              </Button>
            </Tooltip>{" "}
            <Tooltip placement="top" title="Delete">
              <Button
                type="danger"
                className="delete-btn"
                onClick={() => this.confirmDelete([record._id["$id"]])}
              >
                <Icon component={DeleteReviewIcon} />
              </Button>
            </Tooltip>
          </div>
        )
      }
    ];

    return (
      <div className="user-announcement">
        <MainHeadingBar
          title="Announcements"
          helpText="Here you can manage the Announcements."
          pageCTA={
            <span>
              <a
                href="#/"
                className="ant-btn btn-default"
                onClick={() => this.toggleAddAnnouncementModal()}
              >
                <Icon type="plus" />
                Add New Announcement
              </a>
            </span>
          }
          {...this.props}
        />
        <Card style={{ padding: "30px" }}>
          <Row style={{ marginBottom: "2.85rem" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <span>Search</span>
              <SearchField
                placeholder="Search for announcement"
                value={this.state.filters.search}
                onChange={val =>
                  this.setState({
                    filters: { ...this.state.filters, search: val }
                  })
                }
                onSearch={value => this.setFilter("search", value)}
                style={{ width: "20rem", marginLeft: "15px" }}
              />
            </Col>
          </Row>
          <Table
            rowKey={record => record._id["$id"]}
            dataSource={this.state.announcements}
            columns={columns}
            pagination={{ total: this.state.total }}
            rowSelection={rowSelection}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
          <AddAnnouncements
            {...this.props}
            edit={this.state.addAnnouncementModal.edit}
            announcement={this.state.addAnnouncementModal.announcement}
            onAddAnnouncement={() => this.onAddAnnouncement()}
            visible={this.state.addAnnouncementModal.visible}
            toggle={() => this.toggleAddAnnouncementModal()}
          ></AddAnnouncements>

          <LibraryModal
            {...this.props}
            tab={this.state.libraryModalTab}
            onCancel={() => this.toggleLibraryModal()}
            visible={this.state.libraryModalVisible}
            uploadType="announcements"
            onChoose={media => this.onImageChoose(media)}
          />
        </Card>
      </div>
    );
  }
}

export default Announcements;
