import React, { Component } from 'react';
import './index.scss';
import { Button, Divider, Icon, Table, Skeleton, Row, Alert, Tag, Modal, Tooltip } from 'antd';
import { ReactComponent as EditReviewIcon } from '../../../../assets/images/icons/editReview.svg'
import { ReactComponent as DeleteReviewIcon } from '../../../../assets/images/icons/delete.svg';
import { connect } from 'react-redux';
import { getUsersByDomid, deleteUser } from '../../../../helpers/ajax/settingsAjax';
import UserModal from './UserModal';

class UserSettings extends Component {
    state = {
        error: false,
        errorMsg: '',
        loading: true,
        users: [],
        total: 0,
        pagination: { showSizeChanger: true, total: 200 },
        modalVisible: false,
        modalType: 'add_modal',
        userToEdit: {}
    };

    fetch = () => {
        getUsersByDomid(this.props.auth.domain.id).then(res => {
            const { pagination } = this.state;
            pagination.total = res.total;

            this.setState({
                ...this.state,
                ...res,
                pagination
            });
        });
    };

    componentDidMount = () => {
        this.fetch();
    }

    confirmDelete = (uid, name) => {
        Modal.confirm({
            title: 'Are you sure',
            content: `Are you sure you want to delete ${name.charAt(0).toUpperCase() + name.slice(1)}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => deleteUser(uid, this.props.auth.domain.id).then(res => {
                if (!res.error) {
                    this.fetch();
                }
            })
        });
    }

    changeState = (newState) => {
        this.setState({
            ...this.state,
            ...newState
        });
    };

    render() {
        const { error, errorMsg, loading, users, pagination, modalType, modalVisible, userToEdit } = this.state;

        if (loading) {
            return <div style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </div>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        const columns = [{
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0),
            render: text => <p className="user-name">{text}</p>
        }, {
            title: 'Email Id',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.charCodeAt(0) - b.email.charCodeAt(0),
        }, {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: text => <Tag color="#36C626" className="role-tag">{text}</Tag>
        }, {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            render: (_, record) => <div className="actions-div">
                <Tooltip title="Edit User"><Button type="default" className="edit-btn" onClick={() => {
                    this.changeState({
                        modalVisible: true,
                        modalType: 'edit_modal',
                        userToEdit: record
                    })
                }}><Icon component={EditReviewIcon} /></Button></Tooltip>
                <Tooltip title="Delete User"><Button type="danger" className="delete-btn" onClick={() => this.confirmDelete(record.id, record.name)}><Icon component={DeleteReviewIcon} /></Button></Tooltip>
            </div>,
            width: 200
        }];
        const dataSource = users.map(user => ({
            key: user._id.$id,
            id: user._id.$id,
            name: user.user_fname,
            email: user.user_email,
            role: user.user_domains[this.props.auth.domain.id],
        }));

        return (
            <div className="user-settings">
                <h3 className="heading">
                    <span className="text">Users <Tooltip title="Here you can add and manage the users into your account. "><Icon type="info-circle" style={{ fontSize: "1.5rem" }} /></Tooltip></span>

                    <Button
                        className="add-user-btn"
                        onClick={() => { this.changeState({ modalVisible: true, modalType: 'add_modal' }) }}
                    >
                        <Icon type="plus" size="large" />Add New User
                    </Button>
                </h3>

                <Divider />

                <p className="disclaimer">
                    Adding a user allows you to invite users & collaborate with you to help you manage your LetConvert Account. With this, you also get the option to assign new users access level roles for this list.
                </p>

                <Table
                    className="records-table"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                />

                <UserModal
                    modalType={modalType}
                    modalVisible={modalVisible}
                    closeModal={() => {
                        this.changeState({ modalVisible: false });
                        this.fetch();
                    }}
                    domid={this.props.auth.domain.id}
                    user={userToEdit}
                />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(UserSettings);