import React, { Component } from "react";
import { Modal, Select, Button, message } from "antd";
import "./index.scss";
import axios from "axios";

const { Option } = Select;

class FacebookReviewModal extends Component {
  state = {
    loading: false,
    selectedUrl: "",
    fbPages: []
  };

  onSaveUrl() {
    this.setState(
      {
        loading: true
      },
      () => {
        axios({
          url: "/fbreviews/create_page_source",
          method: "POST",
          data: {
            domid: this.props.match.params.domid,
            pageid: this.state.selectedUrl
          }
        })
          .then(res => {
            if (res.data.success === 1) {
              this.setState({
                loading: false
              });
              message.success("Facebook page added successfully!");
              this.props.onToggleFacebookModal();
              this.props.fetchReviewSources();
              window.location.search = "";
            } else {
              this.setState({
                loading: false
              });
              message.error(res.data.msg);
            }
          })
          .catch(err => {
            this.setState({
              loading: false
            });
            message.error(err.message);
          });
      }
    );
  }

  handleChange(value) {
    this.setState({
      selectedUrl: value
    });
  }

  render() {
    return (
      <Modal
        title="Facebook Reviews"
        visible={this.props.visible}
        onCancel={this.props.onToggleFacebookModal}
        className="facebook-review"
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
      >
        <div>
          <p>Select a Facebook Page to sync reviews</p>
          <Select
            placeholder="Select Facebook URL"
            style={{ width: "100%" }}
            onChange={value => this.handleChange(value)}
          >
            {this.props.fbPages.map((p, i) => (
              <Option value={p.page_id} key={i}>
                {p.name}
              </Option>
            ))}
          </Select>
          <div className="fb-review-footer">
            <Button
              type="primary"
              onClick={() => this.onSaveUrl()}
              loading={this.state.loading}
            >
              Sync Reviews
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default FacebookReviewModal;
