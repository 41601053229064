import React from "react";
import "./index.scss";
import { Layout, Menu, Icon } from "antd";
import { NavLink } from "react-router-dom";
import { ReactComponent as Snapshot } from "../../../../assets/images/icons/snapshot.svg";
import { ReactComponent as Rocket } from "../../../../assets/images/icons/rocket.svg";
import { ReactComponent as Reviews } from "../../../../assets/images/icons/reviews.svg";
import { ReactComponent as Bars } from "../../../../assets/images/icons/bars.svg";
import { ReactComponent as Appstore } from "../../../../assets/images/icons/appstore-o.svg";
import { ReactComponent as Setting } from "../../../../assets/images/icons/setting.svg";

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

class Sidebar extends React.Component {
  state = {
    openKeys: []
  };
  componentWillReceiveProps(props) {
    this.setState({
      openKeys: props.openKeys ? props.openKeys : []
    });
  }
  render() {
    const { domid } = this.props;
    let showCampaignsSubmenu = false;
    let showReviewsSubmenu = false;
    let showLibrarySubmenu = false;
    if (this.state.openKeys.length) {
      if (this.state.openKeys[0] === "submenu_campaigns")
        showCampaignsSubmenu = true;
      else if (this.state.openKeys[0] === "submenu_reviews")
        showReviewsSubmenu = true;
      else if (this.state.openKeys[0] === "submenu_library")
        showLibrarySubmenu = true;
    }
    return (
      <Sider className="sidebar" width={220}>
        <Menu mode="inline" openKeys={this.state.openKeys}>
          <Item key="snapshot">
            <NavLink exact to={`/${domid}/`}>
              <Icon component={Snapshot} />
              Snapshot
            </NavLink>
          </Item>

          {showCampaignsSubmenu ? (
            <SubMenu
              key="submenu_campaigns"
              title={
                <span>
                  <Icon component={Rocket} />
                  Campaigns
                </span>
              }
              onTitleClick={() =>
                this.props.history.push(`/${domid}/active_campaigns`)
              }
            >
              <Item key="1">
                <NavLink to={`/${domid}/active_campaigns`}>
                  Active Campaigns
                </NavLink>
              </Item>
              <Item key="2">
                <NavLink to={`/${domid}/archived_campaigns`}>
                  Archived Campaigns
                </NavLink>
              </Item>
              <Item key="3">
                <NavLink to={`/${domid}/draft_campaigns`}>Drafts</NavLink>
              </Item>
            </SubMenu>
          ) : (
            <Item key="campaigns">
              <NavLink to={`/${domid}/active_campaigns`}>
                <span>
                  <Icon component={Rocket} />
                  Campaigns
                </span>
              </NavLink>
            </Item>
          )}

          {showReviewsSubmenu ? (
            <SubMenu
              key="submenu_reviews"
              title={
                <span>
                  <Icon component={Reviews} />
                  Reviews
                </span>
              }
              onTitleClick={() => this.props.history.push(`/${domid}/reviews`)}
            >
              <Item key="1">
                <NavLink to={`/${domid}/reviews`}>User Reviews</NavLink>
              </Item>
              <Item key="2">
                <NavLink to={`/${domid}/review-pools`}>Review Pools</NavLink>
              </Item>
              <Item key="3">
                <NavLink to={`/${domid}/review-sources`}>
                  Review Sources
                </NavLink>
              </Item>
            </SubMenu>
          ) : (
            <Item key="reviews">
              <NavLink to={`/${domid}/reviews`}>
                <span>
                  <Icon component={Reviews} />
                  Reviews
                </span>
              </NavLink>
            </Item>
          )}

          <Item key="records">
            <NavLink to={`/${domid}/records`}>
              <Icon component={Bars} /> <span className="text">Records</span>
            </NavLink>
          </Item>

          {showLibrarySubmenu ? (
            <SubMenu
              key="submenu_library"
              title={
                <span>
                  <Icon component={Appstore} /> Library
                </span>
              }
              onTitleClick={() => this.props.history.push(`/${domid}/library`)}
            >
              <Item key="1">
                <NavLink exact to={`/${domid}/library`}>
                  Library
                </NavLink>
              </Item>
              <Item key="2">
                <NavLink to={`/${domid}/library/search-gifs`}>
                  Search GIFs
                </NavLink>
              </Item>
              <Item key="3">
                <NavLink to={`/${domid}/library/search-icons`}>
                  Search Icons
                </NavLink>
              </Item>
            </SubMenu>
          ) : (
            <Item key="library">
              <NavLink to={`/${domid}/library`}>
                <Icon component={Appstore} />{" "}
                <span className="text">Library</span>
              </NavLink>
            </Item>
          )}
          <Item key="announcement">
            <NavLink to={`/${domid}/announcements`}>
              <Icon type="notification" />{" "}
              <span className="text">Announcements</span>
            </NavLink>
          </Item>
          <Item key="settings">
            <NavLink to={`/${domid}/settings`}>
              <Icon component={Setting} />{" "}
              <span className="text">Settings</span>
            </NavLink>
          </Item>
        </Menu>
      </Sider>
    );
  }
}

export default Sidebar;
