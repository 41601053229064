import React from 'react';
import './index.scss';
import { Tabs } from 'antd';
import Plan from './Plan';
import { connect } from 'react-redux';
import SubscriptionDetails from './SubscriptionDetails';
import MainHeadingBar from '../Partials/MainHeadingBar';
import History from './History';

const { TabPane } = Tabs;

const Billing = (props) => {
    const plan = props.auth.loginplan;
    return (
        <div className="billing">
            <MainHeadingBar title="Billing" helpText="Manage your billing here." {...props} />
            <Tabs tabPosition="left" defaultActiveKey="1">
                <TabPane tab="Plan" key="1">
                    <Plan plan={plan} />
                </TabPane>
                <TabPane tab="Subscription Details" key="2">
                    <SubscriptionDetails plan={plan} user={props.auth.user} />
                </TabPane>
                <TabPane tab="History" key="3">
                    <History />
                </TabPane>
            </Tabs>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
};

export default connect(mapStateToProps)(Billing);