import React, { Component } from 'react';
import './index.scss';
import { Card, Button, Icon, Row, Col, message, Modal, Checkbox, Pagination, Skeleton, Alert } from 'antd';
import { getLibraryMedia, deleteLibraryMedia } from '../../../helpers/ajax/libraryAjax';
import { ReactComponent as NoDataIcon } from '../../../assets/images/icons/no_data_found.svg';
import { CDN_URL } from '../../../config';
import MainHeadingBar from '../Partials/MainHeadingBar';
import LibraryModal from './LibraryModal';
import moment from 'moment';
const { Meta } = Card;
class Library extends Component {
    state = {
        selectedMedia: [],
        media: [],
        total: 0,
        loading: true,
        error: false,
        errorMsg: '',
        paging: {
            pageSize: 12,
            page: 1
        },
        libraryModal: {
            visible: false,
            type: "reviews"
        }
    };

    onSelectChange = (selectedMedia) => {
        this.setState({ selectedMedia });
    };

    fetchLibraryMedia() {
        getLibraryMedia(
            this.props.match.params.domid,
            this.state.paging.pageSize,
            (this.state.paging.page - 1) * this.state.paging.pageSize,
            undefined,
            true
        ).then(res => this.setState(res));
    }

    componentDidMount() {
        this.fetchLibraryMedia();
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            media: [],
            loading: true,
            paging: {
                ...this.state.pagination,
                page: pager.current
            }
        }, () => this.fetchLibraryMedia());
    };

    deleteSelectedMedia(ids) {
        deleteLibraryMedia(this.props.match.params.domid, ids)
            .then((res) => {
                if (res.data.success === 1) {
                    this.setState({
                        media: this.state.media.filter((r) => ids.indexOf(r._id) === -1)
                    })
                    message.success("Image(s) deleted successfully!");
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => {
                message.error(e.message);
            })
    }

    confirmDelete(ids) {
        const that = this;
        Modal.confirm({
            title: 'Are you sure',
            content: 'Are you sure you want to delete the selected image(s). Once deleted, the image(s) cannot be recovered',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                that.deleteSelectedMedia(ids);
            }
        });
    }

    onSelect(id) {
        const checked = this.state.selectedMedia.findIndex((m) => m === id) > -1 ? false : true;
        if (checked) {
            this.setState({
                selectedMedia: [...this.state.selectedMedia, id]
            })
        } else {
            this.setState({
                selectedMedia: this.state.selectedMedia.filter((m) => m !== id)
            })
        }
    }

    selectAll(checked) {
        if (checked) {
            this.setState({
                selectedMedia: this.state.media.map((m) => m._id)
            })
        } else {
            this.setState({
                selectedMedia: []
            })
        }
    }

    goToPage(page) {
        this.setState({
            media: [],
            selectedMedia: [],
            loading: true,
            paging: {
                ...this.state.paging,
                page
            }
        }, () => this.fetchLibraryMedia());
    }

    toggleLibraryModal(type = "campaigns") {
        this.setState({
            libraryModal: {
                visible: !this.state.libraryModal.visible,
                type
            }
        })
    }

    render() {
        const PageWrapper = (children) => (
            <div className="library">
                <MainHeadingBar title="Library" helpText="Here you can manage all your images." pageCTA={
                    <span><a href="#/" className="ant-btn ant-btn-default" onClick={() => this.toggleLibraryModal()}><Icon type="plus" /> Upload Images</a></span>
                } {...this.props} />
                {children}
                <LibraryModal
                    {...this.props}
                    onCancel={() => this.toggleLibraryModal()}
                    visible={this.state.libraryModal.visible}
                    uploadType={this.state.libraryModal.type}
                    hidePicker={true}
                    onChoose={() => this.setState({
                        media: [],
                        loading: true,
                        libraryModal: {
                            ...this.state.libraryModal,
                            visible: false
                        },
                        paging: {
                            ...this.state.paging,
                            page: 1
                        }
                    }, () => this.fetchLibraryMedia())} />
            </div>
        );

        if (this.state.loading) {
            return PageWrapper(
                <Card style={{ marginTop: "10px" }}><Skeleton active /></Card>
            )
        }

        if (!this.state.loading && this.state.error) {
            return PageWrapper(
                <Alert
                    message="Error"
                    description={this.state.errorMsg}
                    type="error"
                    showIcon
                />
            )
        }

        if (this.state.media.length === 0) {
            return PageWrapper(
                <Card style={{ marginBottom: "20px", textAlign: "center" }}>
                    <Row>
                        <NoDataIcon />
                        <p style={{ margin: 0 }}>No Images Found!</p>
                    </Row>
                </Card>
            );
        }

        return PageWrapper(
            <Card style={{ padding: "30px", marginTop: "2.5rem" }}>
                <Row style={{ marginBottom: "2.5rem" }}>
                    <Checkbox
                        defaultChecked={this.state.selectedMedia.length === this.state.media}
                        indeterminate={this.state.selectedMedia.length > 0 && this.state.selectedMedia.length < this.state.media.length}
                        onChange={(e) => this.selectAll(e.target.checked)}> Select All</Checkbox>
                    {this.state.selectedMedia.length > 0 &&
                        <Button
                            style={{ background: "#F84B3D", color: "white", marginLeft: "1rem" }}
                            onClick={() => this.confirmDelete(this.state.selectedMedia)}>Delete Selected</Button>}
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {this.state.media.map(media => (
                        <Col xs={24} sm={12} md={6} key={media._id}>
                            <Card
                                style={{ margin: "0 auto 2.9rem auto", cursor: "pointer" }}
                                cover={<div style={{
                                    background: `url(${CDN_URL}/${media.media_type}/${media.media_filename})`,
                                    height: "100%",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }} />}
                                onClick={() => this.onSelect(media._id)}
                            >
                                {this.state.selectedMedia.indexOf(media._id) !== -1 && <div className="selected-overlay"></div>}
                                <Meta
                                    title={
                                        <div className="media-meta">
                                            <p>{moment(media.media_created).format("MMM DD, YYYY")}</p>
                                            <Checkbox size="large" checked={this.state.selectedMedia.indexOf(media._id) !== -1}></Checkbox>
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row style={{ textAlign: 'right' }}>
                    <Pagination current={this.state.paging.page} pageSize={this.state.paging.pageSize} onChange={(page) => this.goToPage(page)} total={this.state.total} />
                </Row>
            </Card>
        )
    }
}

export default Library;